import React from 'react'
import './BlogSideCard.css'
import { FaInstagram, FaFacebook, FaYoutube, FaLinkedin } from 'react-icons/fa';
import imageImage from '../images/image2.png';
import gifImage from '../images/bloggif.gif';

const BlogSideCard = () => {
  return (
    <div className='blogSideCard'>
      <img src={imageImage} alt=''/>
      <h4>Get the latest articles delivered to your inbox</h4>
      <input type='email' placeholder='Enter your email' required />
      <button>Subscribe Us</button>
      <img src={gifImage} alt='' className='gif'/>
      <h6>Stay Up to date with Web5</h6>
      <div className="social-icons">
      <a href="https://www.instagram.com/web5_solution/" target="_blank" rel="noopener noreferrer">
        <FaInstagram className="icon" />
      </a>
      <a href="https://www.facebook.com/web5solution" target="_blank" rel="noopener noreferrer">
        <FaFacebook className="icon" />
      </a>
      <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
        <FaYoutube className="icon" />
      </a>
      <a href="https://www.linkedin.com/company/web5-solution/mycompany/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin className="icon" />
      </a>
    </div>
    </div>
  )
}

export default BlogSideCard
