import React from 'react';
import planeAnimation from '../animations/CustomerService.json';
import lampAnimation from '../animations/idea-generation.json';
import blockchainAnimation from '../animations/young-customer-executive.json';
import handshakeAnimation from '../animations/handshake.json';
import ChooseUsCard from './ChooseUsCard';
import './ChooseUs.css';

const chooseus = [
  { title: "24/7 Availability", description: "We offer round-the-clock support and quick turnaround times to meet your needs anytime, anywhere.", animationData: planeAnimation, className: 'card-plane' },
  { title: "Client-Centric Approach", description: "Our focus is on understanding and achieving your business goals with tailored solutions.", animationData: blockchainAnimation, className: 'card-blockchain' },
  { title: "Innovative Expertise", description: " We combine creativity with strategic thinking to deliver cutting-edge blockchain solutions.", animationData: lampAnimation, className: 'card-lamp' },
  { title: "Reliability and Responsibility", description: "Count on us for dependable services and a commitment to transparency and authenticity.", animationData: handshakeAnimation, className: 'card-handshake' },
];

function ChooseUs() {
  return (
    <div className='choose-us'>
      <h3>Why Choose Us</h3>
      <div className="choose-us-card-grid">
        {chooseus.map((service, index) => (
          <ChooseUsCard
            key={index}
            title={service.title}
            description={service.description}
            animationData={service.animationData}
            className={service.className}
          />
        ))}
      </div>
    </div>
  );
}

export default ChooseUs;
