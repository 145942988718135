import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSearch, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import BlogCards from "./BlogCards";
import BlogSideCard from "./BlogSideCard";
import blogsData from "../data/blogs.json";
import { MultiSelect } from "react-multi-select-component";
import { slugify } from "../utils/slugify"; // Slugify utility function
import "./Blog.css"; // Import your CSS

const categories = [
  { label: "All", value: "All" },
  { label: "Blockchain", value: "Blockchain" },
  { label: "Crypto", value: "Crypto" },
  { label: "AI", value: "AI" },
  { label: "Automation", value: "Automation" },
  { label: "Infrastructure", value: "Infrastructure" },
];

const Blog = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cardsPerPage = 6;
  const totalPages = Math.ceil(blogsData.blogs.length / cardsPerPage);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname, currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (selected) => {
    setSelectedCategory(selected);
    setCurrentPage(1);
  };

  const selectedCategoryValues = selectedCategory.map((category) => category.value);

  const filteredBlogs = blogsData.blogs.filter((blog) => {
    const matchesCategory =
      selectedCategoryValues.length === 0 ||
      selectedCategoryValues.includes("All") ||
      blog.categories.some((cat) => selectedCategoryValues.includes(cat));
    const matchesSearchTerm = blog.title.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearchTerm;
  });

  const currentCards = filteredBlogs.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handleBlogClick = (title) => {
    const slug = slugify(title); // Convert title to a slug
    navigate(`/blog/${slug}`);
  };

  const renderPageNumbers = () => {
    return Array.from({ length: totalPages }, (_, index) => {
      const pageNum = index + 1;
      return (
        <button
          key={pageNum}
          onClick={() => setCurrentPage(pageNum)}
          className={`page-number ${currentPage === pageNum ? "active-btn" : ""}`}
        >
          {pageNum}
        </button>
      );
    });
  };

  return (
    <div className="blogs">
      <h2 className="blogssheading">Blogs</h2>
      <div className="blogs-top">
        <div className="categories">
          <span className="category">Categories:</span>
          {isMobileView ? (
            <MultiSelect
              options={categories}
              value={selectedCategory}
              onChange={handleCategoryChange}
              labelledBy="Select categories"
              hasSelectAll={false}
            />
          ) : (
            categories.map((category) => (
              <span
                key={category.value}
                onClick={() =>
                  handleCategoryChange([{ label: category.label, value: category.value }])
                }
                className={selectedCategoryValues.includes(category.value) ? "active" : ""}
              >
                {category.label}
              </span>
            ))
          )}
        </div>
        <div className="search-icon">
          <FaSearch />
          <input
            type="text"
            placeholder="Search for a Keyword"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="blog-main-card">
        <div className="blogCard-both">
          <div className="blog-card-grid">
            {currentCards.map((blog) => (
              <BlogCards
                key={blog.id}
                title={blog.title1}
                description={blog.caption1}
                image={require(`../images/${blog.image}`)}
                date={blog.date}
                onClick={() => handleBlogClick(blog.title)} // Pass the title for slug-based routing
              />
            ))}
          </div>
          <div className="pagination2">
            <button
              onClick={handlePrevClick}
              disabled={currentPage === 1}
              className="left-page-btn"
            >
              <FaArrowLeft />
            </button>
            {renderPageNumbers()}
            <button
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
              className="left-page-btn"
            >
              <FaArrowRight />
            </button>
          </div>
        </div>
        <div className="sideCard">
          <BlogSideCard />
        </div>
      </div>
    </div>
  );
};

export default Blog;
