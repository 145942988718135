import React from 'react';
import './LoveWeb5.css';
import { AiFillStar } from 'react-icons/ai';
import bussinessImage from '../images/clients (1).svg';
import worldImage from '../images/offices.svg';
import personImage from '../images/clients.svg';
import trophyImage from '../images/trophy.svg';

const LoveWeb5 = () => {
    return (
        <div className="love-web5-container">
            <div className="header-content">
                <h2 style={{fontWeight: "700",fontSize: "34px"}}>Experience the Excellence of Web5 Solution</h2>
            </div>
            <div className="stars">
                {[...Array(5)].map((_, index) => (
                    <AiFillStar key={index} className="star" />
                ))}
            </div>
            <div className="icons-love">
                <div className="icon-love">
                <img className="icon-svg" src={worldImage} alt=''/> 
                <div className='icon-span'>
                <p>3+</p>
                <p>Worldwide Offices</p>
                </div>
                </div  >
                <div className="icon-love">
                <img className="icon-svg" src={trophyImage} alt=''/> 

                    <div className='icon-span'>
                    <p>5+</p>
                    <p>Award Winning</p>
                    </div>


                </div>
                <div className="icon-love">
                <img className="icon-svg" src={bussinessImage} alt=''/> 
                <div className='icon-span'> 
                <p>100+</p>
                <p>Clients</p>
                </div>


                </div>
                <div className="icon-love">
                <img className="icon-svg" src={personImage} alt=''/> 
                <div className='icon-span'>
                <p>50+</p>
                <p>Team Members</p>
                </div>


                </div>
            </div>
        </div>
    );
};

export default LoveWeb5;
