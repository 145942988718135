import React from 'react';
import './OurTeamCard.css';

const OurTeamCard = ({ title, image, showSvg, para }) => {
  return (
    <div className="ourTeamCard">
      <div className="card-container-team">
        <div className="card-team">
          <div className="front-content">
            <img src={image} alt={title} className="card-image-team" />
            <h5>{title}</h5>
          </div>
          <div className="content-team">
            <p>{para}</p>
          </div>
        </div>
      </div>

      {showSvg && (
        <svg
          className="card-svg-team"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="26"
          viewBox="0 0 17 26"
          fill="none"
        >
          <path
            d="M3 3L13 12.1525L3 23"
            stroke="#494949"
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
      )}
    </div>
  );
};

export default OurTeamCard;
