import React from 'react';
import './WhoWeAre2.css';
import teamImage from '../images/1.jpg';
import teamImage2 from '../images/Rectangle 25 (1).png'
import CustomButton from './CustomButton';

const WhoWeAre2 = ({ chooseUs = {} }) => {
    return (
        <section className="who-we-are2">
            <h2>Who Are We?</h2>
            <div className="who-we-are-container2">
                <div className="image-stack2">
                    <div className="image-stack__item image-stack__item--top2">
                        <img src={teamImage} alt="Team"  loading='lazy' />
                    </div>
                    <div className="image-stack__item image-stack__item--middle2">
                        <img src={teamImage2} alt="Team"  loading='lazy'/>
                    </div>
                    <div className="image-stack__item image-stack__item--bottom2">
                        <img src={teamImage2} alt="Team"  loading='lazy'/>
                    </div>
                </div>
                <div className="who-we-are-text2">
                    
                    <h3>{chooseUs.section_heading}</h3>
                    <p>
                {chooseUs.section_paragraph}

                    </p>
                    <div className="stats2">
                        <div className="stat2">
                            <h4>330 +</h4>
                            <p>Companies helped</p>
                        </div>
                        <div className="stat2">
                            <h4>230 +</h4>
                            <p>Revenue generated</p>
                        </div>
                    </div>
                    <CustomButton buttonText='Be Our Partners'/>
                </div>
            </div>
        </section>
    );
};

export default WhoWeAre2;
