import React, { useState, useEffect } from 'react';
import supplyChainIcon from '../images/supply-chain 1.svg';
import ecommerceIcon from '../images/ecommerce (1).svg';
import healthIcon from '../images/health.svg';
import educationIcon from '../images/education.svg';
import financeIcon from '../images/finance.svg';
import manuIcon from '../images/manufacturing.svg';
import retailIcon from '../images/retail.svg';
import transportIcon from '../images/transport.svg';
import govIcon from '../images/installation.svg';
import engIcon from '../images/energy-saving.svg';
import './IndustriesWeServe.css';

const IndustriesWeServe = () => {
    const items = [
        { title: "Finance", icon: financeIcon },
        { title: "Transportation", icon: transportIcon },
        { title: "Healthcare", icon: healthIcon },
        { title: "Ecommerce", icon: ecommerceIcon },
        { title: "Education", icon: educationIcon },
        { title: "Energy", icon: engIcon },
        { title: "Retail", icon: retailIcon },
        { title: "Supply Chain", icon: supplyChainIcon },
        { title: "Government", icon: govIcon },
        { title: "Manufacturing", icon: manuIcon },
    ];

    const [showAll, setShowAll] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleView = () => {
        setShowAll(!showAll);
    };

    return (
        <div className="industriesWeServe">
            <h2>Industries We Serve</h2>
            <div className='industry-card'>
                {items.slice(0, isMobile && !showAll ? 6 : items.length).map((item, index) => (
                    <div key={index} className="icon-card23">
                        <img src={item.icon} alt={`${item.title} Icon`} className="icon-image23" />
                        <p>{item.title}</p>
                    </div>
                ))}
            </div>
            {isMobile && (
                <div className="view-more-container">
                    <button className="view-more-button" onClick={toggleView}>
                        {showAll ? "View Less" : "View More"}
                    </button>
                </div>
            )}
        </div>
    );
};

export default IndustriesWeServe;
