import React from 'react'
import './CopyRight.css'

const CopyRight = () => {
  return (
    <div className='footer-base'>
        <div className='allcopy'>
        <span>© 2024Web5Solution. All Right Reserved</span>
        <span className='privacy'>• Privacy Policy</span>
        <span className='privacy'>• Terms & Conditions</span>
        </div>

</div>
  )
}

export default CopyRight
