import React, { useEffect, useState } from "react";
import { FaInstagram, FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
import "./BlogSection.css";
import LatestBlogs from "./LatestBlogs";
import ContactUsForm2 from "./ContactUsForm2";

const BlogSection = ({ title, caption, image, date, content }) => {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleAnchorClick = (event) => {
      event.preventDefault();
      const targetId = event.currentTarget.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const headerOffset = 70;
        const elementPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", handleAnchorClick);
    });

    const handleScroll = () => {
      content.forEach((section, index) => {
        const sectionElement = document.getElementById(`section${index}`);
        const bounding = sectionElement.getBoundingClientRect();

        if (bounding.top <= 300 && bounding.bottom >= 100) {
          setActiveSection(`section${index}`);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("click", handleAnchorClick);
      });
      window.removeEventListener("scroll", handleScroll);
    };
  }, [content]);

  const boldText = (text) => {
    if (!text || typeof text !== "string") return null;

    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line
          .split("**")
          .map((part, i) =>
            i % 2 === 1 ? <strong key={i}>{part}</strong> : part
          )}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="maincontent">
        <div className="content">
          <h1>{title}</h1>
          <img src={require(`../images/${image}`)} alt={title} />

          <h6>{date}</h6>
          <p>{boldText(caption)}</p>
          {content.map((section, index) => (
            <Section
              key={index}
              id={`section${index}`}
              title={section.sectionTitle}
              sectionIntro={section.sectionIntro}
              content={section.sectionContent}
              content2={section.sectionContent2}
              image={section.sectionImage}
            />
          ))}
        </div>
        <div></div>
        <div className="toc-above">
          <div className="d-flex flex-column tableofcontent">
            <div className="toc">
              <h2>Table of Contents</h2>
              <ul>
                {content.map((section, index) => (
                  <li
                    key={index}
                    className={
                      activeSection === `section${index}` ? "active" : ""
                    }
                  >
                    <a href={`#section${index}`}>{section.sectionTitle}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="social-icons2">
              <a
                href="https://www.instagram.com/web5_solution/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="icon" />
              </a>
              <a
                href="https://www.facebook.com/web5solution"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="icon" />
              </a>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/web5-solution/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin className="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <LatestBlogs style={{ backgroundColor: 'black' }} />
      <ContactUsForm2 />
    </>
  );
};

const Section = ({ id, title, sectionIntro, content, content2, image }) => {
  const formatText = (text) => {
    if (!text || typeof text !== "string") return null;
  
    // Split text by new lines first to handle line breaks
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line.split(/(\*\*[^*]+\*\*)/g).map((part, i) => {
          // Regex patterns
          const customLinkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+|mailto:[^\s]+)\)/g;
          const urlRegex = /(https?:\/\/[^\s]+)|(mailto:[^\s]+)/g;
          const boldRegex = /\*\*([^*]+)\*\*/;
  
          // Check if the part matches bold text (e.g., **bold**)
          if (boldRegex.test(part)) {
            const boldText = part.match(boldRegex)[1];
            return <strong key={`${index}-${i}`}>{boldText}</strong>;
          }
  
          // Process [text](URL) pattern for custom links
          const partsWithCustomLinks = part.split(customLinkRegex).map((subPart, j) => {
            if (customLinkRegex.test(subPart)) {
              const match = customLinkRegex.exec(subPart);
              const linkText = match[1];
              const url = match[2];
              return (
                <a href={url} key={`${index}-${i}-${j}`} target="_blank" rel="noopener noreferrer">
                  {linkText}
                </a>
              );
            } else {
              // Process plain URLs and mailto links
              const partsWithLinks = subPart.split(urlRegex).map((subSubPart, k) => {
                if (urlRegex.test(subSubPart)) {
                  return (
                    <a href={subSubPart} key={`${index}-${i}-${j}-${k}`} target="_blank" rel="noopener noreferrer">
                      {subSubPart.replace("mailto:", "")}
                    </a>
                  );
                } else {
                  return subSubPart;
                }
              });
              return <React.Fragment key={`${index}-${i}-${j}`}>{partsWithLinks}</React.Fragment>;
            }
          });
  
          return <React.Fragment key={`${index}-${i}`}>{partsWithCustomLinks}</React.Fragment>;
        })}
        <br />
      </React.Fragment>
    ));
  };
  

  return (
    <div id={id} className="section">
      <h2>{title}</h2>

      {/* Render the introductory content before the main content */}
      {sectionIntro && <p>{formatText(sectionIntro)}</p>}

      {/* Render content as paragraphs or as a list */}
      {Array.isArray(content) ? (
        <ul>
          {content.map((item, index) => (
            <li key={index}>{formatText(item)}</li>
          ))}
        </ul>
      ) : (
        content && <p>{formatText(content)}</p>
      )}

      {/* Render image if it exists */}
      {image && (
        <img
          src={require(`../images/${image}`)}
          alt={title}
          style={{ marginBottom: "20px", marginTop: "20px" }}
        />
      )}

      {/* Render additional content if available */}
      {content2 && <p>{formatText(content2)}</p>}
    </div>
  );
};

export default BlogSection;
