import React from 'react';
import './StudyCase.css';


const StudyCase = ({ useCases = {} }) => {
  // Log useCases to ensure it contains the expected data
  console.log('useCases:', useCases);

  const { section_heading, section_paragraph, cases = [] } = useCases;

  return (
    <div className="studyCaseContainer">
      {section_heading && <h2 className="studyCaseTitle">{section_heading}</h2>}
      {section_paragraph && <p className="studyCaseDescription">{section_paragraph}</p>}

      <div className="studyCaseCards">
        {cases.length > 0 ? (
          cases.map((useCase, index) => (
            <div key={index} className="studyCaseCard">
              <img
                src={require(`../images/${useCase.caseImage}`)}
                alt={useCase.name}
                className="studyCaseCardImage"
                 loading='lazy'
              />
              <div style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: 'space-between', alignItems: 'start' }}>
                <h3 className="studyCaseCardTitle">{useCase.name}</h3>
                <div className="studyCaseCardTag">{useCase.tag || 'GENERAL'}</div>
              </div>
              <p className="studyCaseCardDescription">{useCase.description || 'No description available.'}</p>
            </div>
          ))
        ) : (
          <p className="noUseCasesMessage">No use cases available.</p>
        )}
      </div>
    </div>
  );
};




export default StudyCase;
