import React, { useState, useEffect } from "react";

const ConsentBasedEmailPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    // Show the popup when the user first visits the website
    setShowPopup(true);
  }, []);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (email && consentGiven) {
      console.log("Email collected with consent:", email);
      // Here you can add logic to send the email to your server or database
      setShowPopup(false); // Close popup after submission
    } else {
      alert("Please enter a valid email address and agree to the terms.");
    }
  };

  if (!showPopup) return null;

  return (
    <div style={popupStyles.overlay}>
      <div style={popupStyles.popup}>
        <h2>Stay Updated!</h2>
        <p>
          Enter your email to receive updates. By providing your email, you
          consent to receive promotional and informational emails from us.{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
        <form onSubmit={handleEmailSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={popupStyles.input}
          />
          <div style={popupStyles.checkboxContainer}>
            <input
              type="checkbox"
              checked={consentGiven}
              onChange={(e) => setConsentGiven(e.target.checked)}
              style={popupStyles.checkbox}
            />
            <label>
              I consent to receive email communications from this website.
            </label>
          </div>
          <button type="submit" style={popupStyles.button} disabled={!consentGiven}>
            Submit
          </button>
        </form>
        <button onClick={() => setShowPopup(false)} style={popupStyles.closeButton}>
          X
        </button>
      </div>
    </div>
  );
};

// Basic styling for the popup
const popupStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popup: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "300px",
    textAlign: "center",
  },
  input: {
    padding: "10px",
    margin: "10px 0",
    width: "100%",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  checkbox: {
    marginRight: "8px",
  },
  button: {
    padding: "10px 20px",
    cursor: "pointer",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
};

export default ConsentBasedEmailPopup;
