import React from 'react';
import './HowWorksCards2.css';

const HowWorksCards2 = ({ title, subtitle, content, image }) => {
  return (
    <div className="hw-card2">
      <div className="hw-card-content">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
      <div className='hw-image1'>
      {/* <h3 className='hw-subtitle2'>{subtitle}</h3> */}
      <img src={image} alt={title} className="card-image" />
      </div>
    </div>
  );
};

export default HowWorksCards2;