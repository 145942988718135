import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./LatestBlogs.css";
import { FaArrowRight } from "react-icons/fa";
import blogsData from "../data/blogs.json"; 
import { slugify } from "../utils/slugify"; // Slugify utility function

const LatestBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(blogsData.blogs.slice(0, 3)); // Displaying only the latest 3 blogs
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (title) => {
    const slug = slugify(title); // Convert title to a URL-friendly slug
    navigate(`/blog/${slug}`); // Navigate to the dynamic route
  };

  const getImageSrc = (imageName) => {
    try {
      return require(`../images/${imageName}`);
    } catch (err) {
      console.error(`Image not found: ${imageName}`, err);
      return ''; // return a placeholder or empty string if the image is not found
    }
  };

  return (
    <div className="blogmain">
      <h3>Latest Blogs</h3>
      <div className="blogmain-card2">
        {blogs.map((blog, index) => (
          <div
            key={blog.id}
            className={`blogmain-card2-${index}`}
            onClick={() => handleCardClick(blog.title)} // Passing blog title for slug-based navigation
          >
            <img
              src={getImageSrc(blog.image)}
              alt={blog.title}
              className="blogmain-card2-img"
            />
            <div className="blogmain-card2-content">
              <p className="blogmain-tag">Blog</p>
              <h5>{blog.title1}</h5>
              <p>
                {blog.caption1}
                <span
                  style={{ display: "inline-block" }}
                  className="arrow-span"
                >
                  <FaArrowRight className="arrow" />
                </span>
              </p>
              <span className="know-more-blog">
                Know More 
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestBlogs;
