import React from "react";
import "./RelatedServices.css";

const RelatedServices = ({ servicesOffered }) => {
  if (!servicesOffered || !Array.isArray(servicesOffered.items)) {
    return <p>No related services available</p>;
  }

  return (
    <section className="relatedServices__section">
      <div className="relatedServices__container">
        <h2 className="relatedServices__title">{servicesOffered.mainHeading}</h2>
        <p className="relatedServices__description">
          {servicesOffered.sectionDescription}
        </p>
        <div className="relatedServices__cards">
          {servicesOffered.items.map((service, index) => (
            <div key={index} className="relatedServices__card">
              
              <img
                src={require(`../images/${service.itemsImage}`)} 
                alt={`${service.name} Icon`}
                className="relatedServices__icon"
                 loading='lazy'
              />
              <h3 className="relatedServices__cardTitle">{service.name}</h3>
              <p className="relatedServices__cardDescription">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RelatedServices;
