import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./ContactUsForm2.css";
import Loader from "./Loader"; // Import the Loader component
import CustomButton from "./CustomButton";
import successImage from "../images/Successfully.gif";
import axios from "axios";

const Modal = ({ show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <img
          src={successImage}
          alt="Success"
          style={{ width: "150px", height: "150px" }}
        />
        <p style={{ textAlign: "center" }}>
          Details Successfully Submitted! <br />
          We will contact you soon.
        </p>
        <button onClick={onClose} className="close-button">
          X
        </button>
      </div>
    </div>
  );
};

const ContactUsForm2 = () => {
  const [selectedService, setSelectedService] = useState("Blockchain");
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const [formDetails, setFormDetails] = useState({
    from_name: "",
    to_email: "contact@web5solution.com",
    fullname: "",
    email: "",
    phone: "",
    howDidYouHear: "",
    project: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const sendWhatsAppNotification = async () => {
    const message = `You have a new contact form submission!\nName: ${formDetails.fullname}\nEmail: ${formDetails.email}\nPhone: ${formDetails.phone}\nProject: ${formDetails.project}\nService: ${selectedService}`;

    const data = new URLSearchParams({
      To: "whatsapp:+919817726438", 
      From: "whatsapp:+14155238886",
      Body: message,
    });

    try {
      const response = await axios.post(
        "https://api.twilio.com/2010-04-01/Accounts/AC6f001c6c7212333d8b47d36bc38f5b47/Messages.json",
        data,
        {
          auth: {
            username: "AC6f001c6c7212333d8b47d36bc38f5b47",
            password: "4f9fdf3b28a58e24655593d81161a6e0",
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log("WhatsApp notification sent!", response.data);
    } catch (error) {
      console.log("Failed to send WhatsApp notification:", error.response ? error.response.data : error.message);
    }
  };

  const sendEmail = (event) => {
    event.preventDefault();
    setLoading(true); // Start loading

    const emailParams = {
      ...formDetails,
      selectedService,
      fileName,
    };

    emailjs
      .send(
        "service_5proq6w",
        "template_piuk7jw",
        emailParams,
        "AWvxc47mipQeOEPBL"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFileName(null);
          setSelectedService("Blockchain");
          sendWhatsAppNotification();
          setFormDetails({
            from_name: "",
            to_email: "contact@web5solution.com",
            fullname: "",
            email: "",
            phone: "",
            howDidYouHear: "",
            project: "",
          });
          setShowModal(true);
          setLoading(false); 
        },
        (error) => {
          console.log("FAILED...", error);
          setLoading(false); 
        }
      );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="main-container-contact">
      {loading && <Loader />} {/* Show loader if loading */}
      <div className="contact-main-div">
        <h4>Reach Out to Us</h4>
        <p>
          We offer top-notch services customized to suit your requirements and
          goals! Feel free to share any thoughts or inquiries with us. Our
          skilled team is dedicated to supporting you in exploring possibilities
          and achieving success through blockchain technology.
        </p>
        <p className="hide-p-mobile">
          Feel free to reach out to us for a chat about this today.
          <ul>
            <li>Your endeavor in the realm of technology.</li>
            <li>Customized development requirements</li>
          </ul>
          Please complete the form. We will reach out to you shortly to discuss
          how we can help make your blockchain ideas a reality.
        </p>
      </div>
      <div className="contact-form2">
        <div className="contact-service-buttons">
          {["Blockchain", "AI Services", "Web Development", "Mobile App Development"].map((service, index) => (
            <button
              key={index}
              className={selectedService === service ? "active" : ""}
              onClick={() => handleServiceClick(service)}
            >
              {service}
            </button>
          ))}
        </div>
        <form onSubmit={sendEmail}>
          <div className="form-group">
            <input
              type="text"
              placeholder="First Name *"
              name="from_name"
              required
              value={formDetails.from_name}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="Last Name *"
              name="fullname"
              required
              value={formDetails.fullname}
              onChange={handleInputChange}
            />
            <input
              type="email"
              placeholder="Email *"
              name="email"
              required
              value={formDetails.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              placeholder="+91"
              name="phone"
              required
              value={formDetails.phone}
              onChange={handleInputChange}
            />
            <select
              name="howDidYouHear"
              required
              value={formDetails.howDidYouHear}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                How did you hear about us
              </option>
              <option value="google">Google</option>
              <option value="friend">Friend</option>
              <option value="advertisement">Advertisement</option>
            </select>
          </div>
          <div className="form-group" style={{ width: "100%" }}>
            <div style={{ position: "relative", width: "100%" }}>
              <textarea
                name="project"
                placeholder="About Project *"
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  boxSizing: "border-box",
                  paddingRight: "40px",
                }}
                value={formDetails.project}
                onChange={handleInputChange}
              ></textarea>
              <label
                className="attachment-label"
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <input
                  type="file"
                  name="projectFile"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    opacity: 0,
                    zIndex: -1,
                  }}
                  onChange={handleFileChange}
                />
              </label>
            </div>
            {fileName && <p>Selected File: {fileName}</p>}
          </div>
          <div className="privacy-policy">
            <p style={{display:"flex", gap:"10px"}}>
              <input type="checkbox"/>
              By sending this form I confirm that I have read and accept the
              Privacy Policy
            </p>
            <CustomButton buttonText="Send Details" />
          </div>
        </form>

        <Modal show={showModal} onClose={closeModal} />
      </div>
    </div>
  );
};

export default ContactUsForm2;


// import React, { useState, useEffect } from "react";
// import emailjs from "emailjs-com";
// import "./ContactUsForm2.css";
// import CustomButton from "./CustomButton";
// import successImage from "../images/Successfully.gif";
// import axios from "axios";

// const Modal = ({ show, onClose }) => {
//   useEffect(() => {
//     if (show) {
//       const timer = setTimeout(() => {
//         onClose();
//       }, 2000);

//       return () => clearTimeout(timer);
//     }
//   }, [show, onClose]);

//   if (!show) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <img
//           src={successImage}
//           alt="Success"
//           style={{ width: "150px", height: "150px" }}
//         />
//         <p style={{ textAlign: "center" }}>
//           Details Successfully Submitted! <br />
//           We will contact you soon.
//         </p>
//         <button onClick={onClose} className="close-button">
//           X
//         </button>
//       </div>
//     </div>
//   );
// };

// const ContactUsForm2 = () => {
//   const [selectedService, setSelectedService] = useState("Blockchain");
//   const [fileName, setFileName] = useState(null);
//   const [formDetails, setFormDetails] = useState({
//     from_name: "",
//     to_email: "",
//     fullname: "",
//     email: "",
//     phone: "",
//     howDidYouHear: "",
//     project: "",
//   });
//   const [showModal, setShowModal] = useState(false);

//   const handleServiceClick = (service) => {
//     setSelectedService(service);
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFileName(file.name);
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   // Function to send WhatsApp notification via Twilio API
//   const sendWhatsAppNotification = async () => {
//     const message = `You have a new contact form submission!\nName: ${formDetails.fullname}\nEmail: ${formDetails.email}\nPhone: ${formDetails.phone}\nProject: ${formDetails.project}\nService: ${selectedService}`;
//     // console.log("message for WhatsApp === ", message);

//     const data = new URLSearchParams({
//       To: "whatsapp:+919817726438", 
//       From: "whatsapp:+14155238886",
//       Body: message,
//     });

//     try {
//       const response = await axios.post(
//         "https://api.twilio.com/2010-04-01/Accounts/AC6f001c6c7212333d8b47d36bc38f5b47/Messages.json",
//         data,
//         {
//           auth: {
//             username: "AC6f001c6c7212333d8b47d36bc38f5b47",
//             password: "4f9fdf3b28a58e24655593d81161a6e0",
//           },
//           headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//           },
//         }
//       );
//       console.log("WhatsApp notification sent!", response.data);
//     } catch (error) {
//       console.log("Failed to send WhatsApp notification:", error.response ? error.response.data : error.message);
//     }
//   };

 
//   //   const message = `You have a new contact form submission!\nName: ${formDetails.fullname}\nEmail: ${formDetails.email}\nPhone: ${formDetails.phone}\nProject: ${formDetails.project}\nService: ${selectedService}`;
//   //   console.log("mesaage for whatsapp === ", message);
//   //   try {
//   //     const response = await axios.post(
//   //       "https://api.twilio.com/2010-04-01/Accounts/AC6f001c6c7212333d8b47d36bc38f5b47/Messages.json",
//   //       {
//   //         To: "whatsapp:+919817726438",
//   //         From: "whatsapp:+12092274847",
//   //         Body: message,
//   //       },
//   //       {
//   //         auth: {
//   //           username: "AC6f001c6c7212333d8b47d36bc38f5b47",
//   //           password: "4f9fdf3b28a58e24655593d81161a6e0",
//   //         },
//   //       }
//   //     );
//   //     console.log("WhatsApp notification sent!", response.data);
//   //   } catch (error) {
//   //     console.log("Failed to send WhatsApp notification:", error);
//   //   }
//   // };

//   const sendEmail = (event) => {
//     event.preventDefault();

//     const emailParams = {
//       ...formDetails,
//       selectedService,
//       fileName,
//     };

//     emailjs
//       .send(
//         "service_5proq6w",
//         "template_piuk7jw",
//         emailParams,
//         "AWvxc47mipQeOEPBL"
//       )
//       .then(
//         (response) => {
//           console.log("SUCCESS!", response.status, response.text);

//           setFileName(null);
//           setSelectedService("BLOCKCHAIN");
//           setShowModal(true);
//           sendWhatsAppNotification();
//           setFormDetails({
//             from_name: "",
//             to_email: "contact@web5solution.com",
//             fullname: "",
//             email: "",
//             phone: "",
//             howDidYouHear: "",
//             project: "",
//           });
//         },
//         (error) => {
//           console.log("FAILED...", error);
//         }
//       );
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <div className="main-container-contact">
//       <div className="contact-main-div">
//         <h4>Reach Out to Us</h4>
//         <p>
//           We offer top-notch services customized to suit your requirements and
//           goals! Feel free to share any thoughts or inquiries with us. Our
//           skilled team is dedicated to supporting you in exploring possibilities
//           and achieving success through blockchain technology.
//         </p>
//         <p className="hide-p-mobile">
//           Feel free to reach out to us for a chat about this today.
//           <ul>
//             <li>Your endeavor in the realm of technology.</li>
//             <li>Customized development requirements</li>
//           </ul>
//           Please complete the form. We will reach out to you shortly to discuss
//           how we can help make your blockchain ideas a reality.
//         </p>
//       </div>
//       <div className="contact-form2">
//         <div className="contact-service-buttons">
//           {[
//             "Blockchain",
//             "AI Services",
//             "Web Development",
//             "Mobile App Development",
//           ].map((service, index) => (
//             <button
//               key={index}
//               className={selectedService === service ? "active" : ""}
//               onClick={() => handleServiceClick(service)}
//             >
//               {service}
//             </button>
//           ))}
//         </div>
//         <form onSubmit={sendEmail}>
//           <div className="form-group">
//             <input
//               type="text"
//               placeholder="Your Name *"
//               name="from_name"
//               required
//               value={formDetails.from_name}
//               onChange={handleInputChange}
//             />
//             <input
//               type="text"
//               placeholder="Full Name *"
//               name="fullname"
//               required
//               value={formDetails.fullname}
//               onChange={handleInputChange}
//             />
//             <input
//               type="email"
//               placeholder="Email *"
//               name="email"
//               required
//               value={formDetails.email}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="form-group">
//             <input
//               type="tel"
//               placeholder="+91"
//               name="phone"
//               required
//               value={formDetails.phone}
//               onChange={handleInputChange}
//             />
//             <select
//               name="howDidYouHear"
//               required
//               value={formDetails.howDidYouHear}
//               onChange={handleInputChange}
//             >
//               <option value="" disabled>
//                 How did you hear about us
//               </option>
//               <option value="google">Google</option>
//               <option value="friend">Friend</option>
//               <option value="advertisement">Advertisement</option>
//             </select>
//           </div>
//           <div className="form-group" style={{ width: "100%" }}>
//             <div style={{ position: "relative", width: "100%" }}>
//               <textarea
//                 name="project"
//                 placeholder="About Project *"
//                 required
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   boxSizing: "border-box",
//                   paddingRight: "40px",
//                 }}
//                 value={formDetails.project}
//                 onChange={handleInputChange}
//               ></textarea>
//               <label
//                 className="attachment-label"
//                 style={{
//                   position: "absolute",
//                   top: "50%",
//                   transform: "translateY(-50%)",
//                   right: "10px",
//                   cursor: "pointer",
//                 }}
//               >
//                 <input
//                   type="file"
//                   name="projectFile"
//                   style={{
//                     position: "absolute",
//                     top: 0,
//                     right: 0,
//                     opacity: 0,
//                     zIndex: -1,
//                   }}
//                   onChange={handleFileChange}
//                 />
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="16"
//                   height="16"
//                   fill="currentColor"
//                   viewBox="0 0 16 16"
//                   onClick={() =>
//                     document.getElementsByName("projectFile")[0].click()
//                   }
//                 >
//                   <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
//                 </svg>
//                 <span className="attachment-icon">
//                   {fileName ? <i className="fa fa-paperclip"></i> : null}
//                 </span>
//               </label>
//             </div>
//             {fileName && <p>Selected File: {fileName}</p>}
//           </div>
//           <div className="privacy-policy">
//             <p>
//               By sending this form I confirm that I have read and accept the
//               Privacy Policy
//             </p>
//             <CustomButton buttonText="Send Details" />
//           </div>
//         </form>

//         <Modal show={showModal} onClose={closeModal} />
//       </div>
//     </div>
//   );
// };

// export default ContactUsForm2;






// import React, { useState, useEffect } from "react";
// import emailjs from "emailjs-com";
// import "./ContactUsForm2.css";
// import CustomButton from "./CustomButton";
// import successImage from "../images/Successfully.gif";
// import axios from "axios";

// const Modal = ({ show, onClose }) => {
//   useEffect(() => {
//     if (show) {
//       const timer = setTimeout(() => {
//         onClose();
//       }, 2000);

//       return () => clearTimeout(timer);
//     }
//   }, [show, onClose]);

//   if (!show) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <img
//           src={successImage}
//           alt="Success"
//           style={{ width: "150px", height: "150px" }}
//         />
//         <p style={{ textAlign: "center" }}>
//           Details Successfully Submitted! <br />
//           We will contact you soon.
//         </p>
//         <button onClick={onClose} className="close-button">
//           X
//         </button>
//       </div>
//     </div>
//   );
// };

// const ContactUsForm2 = () => {
//   const [selectedService, setSelectedService] = useState("Blockchain");
//   const [fileName, setFileName] = useState(null);
//   const [formDetails, setFormDetails] = useState({
//     from_name: "",
//     to_email: "contact@web5solution.com",
//     fullname: "",
//     email: "",
//     phone: "",
//     howDidYouHear: "",
//     project: "",
//   });
//   const [showModal, setShowModal] = useState(false);

//   const handleServiceClick = (service) => {
//     setSelectedService(service);
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFileName(file.name);
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const sendWhatsAppNotification = async () => {
//     const message = `You have a new contact form submission!\nName: ${formDetails.fullname}\nEmail: ${formDetails.email}\nPhone: ${formDetails.phone}\nProject: ${formDetails.project}\nService: ${selectedService}`;

//     const data = new URLSearchParams({
//       body: message,
//       to: "+919817726438", // No space between 'whatsapp:' and phone number
//       from: "+12092274847",
//     });

//     try {
//       const response = await axios.post(
//         "https://api.twilio.com/2010-04-01/Accounts/AC6f001c6c7212333d8b47d36bc38f5b47/Messages.json",
//         data,
//         {
//           auth: {
//             username: "AC6f001c6c7212333d8b47d36bc38f5b47",
//             password: "4f9fdf3b28a58e24655593d81161a6e0",
//           },
//           headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//           },
//         }
//       );
//       console.log("WhatsApp notification sent!", response.data);
//     } catch (error) {
//       console.log(
//         "Failed to send WhatsApp notification:",
//         error.response ? error.response.data : error.message
//       );
//     }
//   };

//   const sendEmail = (event) => {
//     event.preventDefault();

//     const emailParams = {
//       ...formDetails,
//       selectedService,
//       fileName,
//     };

//     emailjs
//       .send(
//         "service_5proq6w",
//         "template_piuk7jw",
//         emailParams,
//         "AWvxc47mipQeOEPBL"
//       )
//       .then(
//         (response) => {
//           console.log("SUCCESS!", response.status, response.text);

//           setFileName(null);
//           setSelectedService("BLOCKCHAIN");
//           setShowModal(true);
//           sendWhatsAppNotification();
//           setFormDetails({
//             from_name: "",
//             to_email: "",
//             fullname: "",
//             email: "",
//             phone: "",
//             howDidYouHear: "",
//             project: "",
//           });
//         },
//         (error) => {
//           console.log("FAILED...", error);
//         }
//       );
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <div className="main-container-contact">
//       <div className="contact-main-div">
//         <h4>Reach Out to Us</h4>
//         <p>
//           We offer top-notch services customized to suit your requirements and
//           goals! Feel free to share any thoughts or inquiries with us. Our
//           skilled team is dedicated to supporting you in exploring possibilities
//           and achieving success through blockchain technology.
//         </p>
//         <p className="hide-p-mobile">
//           Feel free to reach out to us for a chat about this today.
//           <ul>
//             <li>Your endeavor in the realm of technology.</li>
//             <li>Customized development requirements</li>
//           </ul>
//           Please complete the form. We will reach out to you shortly to discuss
//           how we can help make your blockchain ideas a reality.
//         </p>
//       </div>
//       <div className="contact-form2">
//         <div className="contact-service-buttons">
//           {[
//             "Blockchain",
//             "AI Services",
//             "Web Development",
//             "Mobile App Development",
//           ].map((service, index) => (
//             <button
//               key={index}
//               className={selectedService === service ? "active" : ""}
//               onClick={() => handleServiceClick(service)}
//             >
//               {service}
//             </button>
//           ))}
//         </div>
//         <form onSubmit={sendEmail}>
//           <div className="form-group">
//             <input
//               type="text"
//               placeholder="Your Name *"
//               name="from_name"
//               required
//               value={formDetails.from_name}
//               onChange={handleInputChange}
//             />
//             <input
//               type="text"
//               placeholder="Full Name *"
//               name="fullname"
//               required
//               value={formDetails.fullname}
//               onChange={handleInputChange}
//             />
//             <input
//               type="email"
//               placeholder="Email *"
//               name="email"
//               required
//               value={formDetails.email}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="form-group">
//             <input
//               type="tel"
//               placeholder="+91"
//               name="phone"
//               required
//               value={formDetails.phone}
//               onChange={handleInputChange}
//             />
//             <select
//               name="howDidYouHear"
//               required
//               value={formDetails.howDidYouHear}
//               onChange={handleInputChange}
//             >
//               <option value="" disabled>
//                 How did you hear about us
//               </option>
//               <option value="google">Google</option>
//               <option value="friend">Friend</option>
//               <option value="advertisement">Advertisement</option>
//             </select>
//           </div>
//           <div className="form-group" style={{ width: "100%" }}>
//             <div style={{ position: "relative", width: "100%" }}>
//               <textarea
//                 name="project"
//                 placeholder="About Project *"
//                 required
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   boxSizing: "border-box",
//                   paddingRight: "40px",
//                 }}
//                 value={formDetails.project}
//                 onChange={handleInputChange}
//               ></textarea>
//               <label
//                 className="attachment-label"
//                 style={{
//                   position: "absolute",
//                   top: "50%",
//                   transform: "translateY(-50%)",
//                   right: "10px",
//                   cursor: "pointer",
//                 }}
//               >
//                 <input
//                   type="file"
//                   name="projectFile"
//                   style={{
//                     position: "absolute",
//                     top: 0,
//                     right: 0,
//                     opacity: 0,
//                     zIndex: -1,
//                   }}
//                   onChange={handleFileChange}
//                 />
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="16"
//                   height="16"
//                   fill="currentColor"
//                   viewBox="0 0 16 16"
//                   onClick={() =>
//                     document.getElementsByName("projectFile")[0].click()
//                   }
//                 >
//                   <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
//                 </svg>
//                 <span className="attachment-icon">
//                   {fileName ? <i className="fa fa-paperclip"></i> : null}
//                 </span>
//               </label>
//             </div>
//             {fileName && <p>Selected File: {fileName}</p>}
//           </div>
//           <div className="privacy-policy">
//             <p>
//               By sending this form I confirm that I have read and accept the
//               Privacy Policy
//             </p>
//             <CustomButton buttonText="Send Details" />
//           </div>
//         </form>

//         <Modal show={showModal} onClose={closeModal} />
//       </div>
//     </div>
//   );
// };

// export default ContactUsForm2;


// import React, { useState, useEffect } from 'react';
// import emailjs from 'emailjs-com';
// import './ContactUsForm2.css';
// import CustomButton from './CustomButton';
// import successImage from "../images/Successfully.gif";

// const Modal = ({ show, onClose }) => {
//   useEffect(() => {
//     if (show) {
//       const timer = setTimeout(() => {
//         onClose();
//       }, 2000);

//       return () => clearTimeout(timer);
//     }
//   }, [show, onClose]);

//   if (!show) return null;

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <img src={successImage} alt="Success" style={{ width: '150px', height: '150px' }} />
//         <p style={{textAlign:"center"}}>Details Successfully Submitted! <br />We will contact you soon.</p>
//         <button onClick={onClose} className="close-button">X</button>
//       </div>
//     </div>
//   );
// };

// const ContactUsForm2 = () => {
//   const [selectedService, setSelectedService] = useState('Blockchain');
//   const [fileName, setFileName] = useState(null);
//   const [formDetails, setFormDetails] = useState({
//     from_name: '',
//     to_email: 'contact@web5solution.com',
//     fullname: '',
//     email: '',
//     phone: '',
//     howDidYouHear: '',
//     project: ''
//   });
//   const [showModal, setShowModal] = useState(false);

//   const handleServiceClick = (service) => {
//     setSelectedService(service);
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFileName(file.name);
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormDetails(prevDetails => ({
//       ...prevDetails,
//       [name]: value
//     }));
//   };

//   const sendEmail = (event) => {
//     event.preventDefault();

//     const emailParams = {
//       ...formDetails,
//       selectedService,
//       fileName
//     };

//     emailjs.send('service_5proq6w', 'template_piuk7jw', emailParams, 'AWvxc47mipQeOEPBL')
//       .then((response) => {
//         console.log('SUCCESS!', response.status, response.text);
//         setFormDetails({
//           from_name: '',
//           to_email: 'contact@web5solution.com',
//           fullname: '',
//           email: '',
//           phone: '',
//           howDidYouHear: '',
//           project: ''
//         });
//         setFileName(null);
//         setSelectedService('BLOCKCHAIN');
//         setShowModal(true);
//       }, (error) => {
//         console.log('FAILED...', error);
//       });
//   };

//   const closeModal = () => {
//     setShowModal(false); // Close modal
//   };

//   return (
//     <div className='main-container-contact'>
//       <div className='contact-main-div'>
//         <h4>Reach Out to Us</h4>
//         <p>We offer top-notch services customized to suit your requirements and goals! Feel free to share any thoughts or inquiries with us. Our skilled team is dedicated to supporting you in exploring possibilities and achieving success through blockchain technology.</p>
//         <p className='hide-p-mobile'>Feel free to reach out to us for a chat about this today.
//           <ul>
//             <li>Your endeavor in the realm of technology.</li>
//             <li>Customized development requirements</li>
//           </ul>
//           Please complete the form. We will reach out to you shortly to discuss how we can help make your blockchain ideas a reality.
//         </p>
//       </div>
//       <div className="contact-form2">
//         <div className="contact-service-buttons">
//           {['Blockchain', 'AI Services', 'Web Development', 'Mobile App Development'].map((service, index) => (
//             <button
//               key={index}
//               className={selectedService === service ? 'active' : ''}
//               onClick={() => handleServiceClick(service)}
//             >
//               {service}
//             </button>
//           ))}
//         </div>
//         <form onSubmit={sendEmail}>
//           <div className="form-group">
//             <input
//               type="text"
//               placeholder="Your Name *"
//               name="from_name"
//               required
//               value={formDetails.from_name}
//               onChange={handleInputChange}
//             />
//             <input
//               type="text"
//               placeholder="Full Name *"
//               name="fullname"
//               required
//               value={formDetails.fullname}
//               onChange={handleInputChange}
//             />
//             <input
//               type="email"
//               placeholder="Email *"
//               name="email"
//               required
//               value={formDetails.email}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="form-group">
//             <input
//               type="tel"
//               placeholder="+91"
//               name="phone"
//               required
//               value={formDetails.phone}
//               onChange={handleInputChange}
//             />
//             <select
//               name="howDidYouHear"
//               required
//               value={formDetails.howDidYouHear}
//               onChange={handleInputChange}
//             >
//               <option value="" disabled>How did you hear about us</option>
//               <option value="google">Google</option>
//               <option value="friend">Friend</option>
//               <option value="advertisement">Advertisement</option>
//             </select>
//           </div>
//           <div className="form-group" style={{ width: '100%' }}>
//             <div style={{ position: 'relative', width: '100%' }}>
//               <textarea
//                 name="project"
//                 placeholder="About Project *"
//                 required
//                 style={{
//                   width: '100%',
//                   padding: '10px',
//                   boxSizing: 'border-box',
//                   paddingRight: '40px'
//                 }}
//                 value={formDetails.project}
//                 onChange={handleInputChange}
//               ></textarea>
//               <label
//                 className="attachment-label"
//                 style={{
//                   position: 'absolute',
//                   top: '50%',
//                   transform: 'translateY(-50%)',
//                   right: '10px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <input
//                   type="file"
//                   name="projectFile"
//                   style={{ position: 'absolute', top: 0, right: 0, opacity: 0, zIndex: -1 }}
//                   onChange={handleFileChange}
//                 />
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="16"
//                   height="16"
//                   fill="currentColor"
//                   viewBox="0 0 16 16"
//                   onClick={() => document.getElementsByName("projectFile")[0].click()}
//                 >
//                   <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
//                 </svg>
//                 <span className="attachment-icon">{fileName ? <i className="fa fa-paperclip"></i> : null}</span>
//               </label>
//             </div>
//             {fileName && <p>Selected File: {fileName}</p>}
//           </div>
//           <div className="privacy-policy">
//             <p>By sending this form I confirm that I have read and accept the Privacy Policy</p>
//             <CustomButton buttonText="Send Details" />
//           </div>
//         </form>

//         {/* Render the Modal */}
//         <Modal show={showModal} onClose={closeModal} />
//       </div>
//     </div>
//   );
// };

// export default ContactUsForm2;
