import React, { useEffect, useRef } from 'react';
import './AboutUs.css';
import OurMission from './OurMission';
import LoveWeb5 from './LoveWeb5';
import LatestBlogs from './LatestBlogs';
import rectangle8Image from '../images/Rectangle8.png';
import rectangle9Image from '../images/block1234.png';
import rectangleImage from '../images/image.png' 
import CustomButton from './CustomButton';
import { Helmet } from 'react-helmet';
import WhoWeAre from './WhoWeAre';

const AboutUs = () => {
  const image2Ref = useRef(null);
  const ourVisionRef = useRef(null);

  const adjustImagePosition = () => {
    const image = image2Ref.current;
    const container = ourVisionRef.current;
    if (image && container) {
      if (window.innerWidth <= 768) { 
        const containerWidth = container.offsetWidth;
        const leftValue = containerWidth * 0.3; 
        image.style.left = `${leftValue}px`;
      } else {
        image.style.left = '120px'; 
      }
    }
  };

  useEffect(() => {
    adjustImagePosition();
    window.addEventListener('resize', adjustImagePosition);
    return () => {
      window.removeEventListener('resize', adjustImagePosition);
    };
  }, []);

  return (
    <div className="about-us">
      <Helmet>
        <title>About Web5solution: Transforming Enterprises with Next-Gen
Technologies.
</title>
        <meta name="description" content="Learn how Web5solution is revolutionizing
businesses with cutting-edge blockchain, metaverse, and AI
solutions. We empower enterprises to innovate, grow, and stay
ahead in the digital age." />
        <meta name="keywords" content="web5solution,web5, Blockchain, Crypto" />
      </Helmet>
      <div className="about-us-main">
        <h2>Who Are We?</h2>
        <div className='about-us-content'>
          <p>
            Web5 Solutions is not just a blockchain development agency; we're a team of passionate innovators, thinkers, and enthusiasts dedicated to reshaping the digital landscape. Our journey began at a blockchain conference, where a group of tech enthusiasts realized their shared vision of setting new standards of quality and ethics in the tech community. This vision led to the founding of Web5 Solutions, a community-driven startup committed to understanding clients' needs and building long-lasting relationships through exceptional service and cutting-edge solutions.
          </p>
          {/* <img src={rectangleImage}/>
          <span>WEB5</span> */}
        </div>
        <div className='ourvision' ref={ourVisionRef}>
          <h3>Our Vision</h3>
          <div className='ourvision-mainCard'>
            <div className='ourvision-card1'>
              <img src={rectangle8Image} alt='' className='image1'/>
              <img src={rectangle9Image} alt='' className='image2' ref={image2Ref}/>
            </div>
            <div className='ourvision-card2'>
              <h4 className='ourvision-h4'>Quality and ethics in Web3 innovation</h4>
              <h6 className='ourvision-span'>
                Our vision at Web5 Solutions is to establish a new benchmark of quality and work ethics within the tech industry. We aim to create a community-driven environment where innovation thrives, and client needs are prioritized. By deeply understanding our clients' businesses and goals, we strive to deliver solutions that not only meet but exceed expectations, fostering trust and long-term partnerships.
              </h6>
              <div className='ourMissionbtn'>
                <CustomButton buttonText="Know More" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurMission />
      {/* <WhoWeAre/> */}
      {/* <LoveWeb5/>
      <LatestBlogs/> */}
    </div>
  );
};

export default AboutUs;
