import React from 'react';
import imageImage from '../images/Frame.svg';
import './CustomButton.css'; // Ensure you have this CSS file in the same directory

const CustomButton = ({ onClick, showAllCards, buttonText }) => {
  const displayText = showAllCards ? 'Know Less' : buttonText;

  return (
    <button className="custom-button" onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {displayText}
      <img src={imageImage} alt="Icon" />
    </button>
  );
}

export default CustomButton;
