import React, { useState, useEffect } from "react";
import { FaRegDotCircle, FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import compImage from "../images/rightimage.png";
import deckPdf from "../images/Web5 Pitch Deck (1).pdf";
import data from "../data/services2.json";
import "./Navbar.css";
import { AiOutlineCaretRight } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [services, setServices] = useState([]); 

  useEffect(() => {
    setServices(data.services || []);
  }, []);

  const handleMobileMenu = () => setIsMobile(!isMobile);
  const closeMobileMenu = () =>{
    setShowDropdown(false)
    setIsMobile(false);
  } 

  const handlePdfOpen = (e) => {
    e.preventDefault();
    window.open(deckPdf, "_blank");
    closeMobileMenu();
  };

  const formatTitleToURL = (title) => {
    return title.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link
          to="/"
          className={`navbar-image ${animate ? "animate" : ""}`}
          onClick={closeMobileMenu}
        >
          <img src={compImage} alt="Logo" />
        </Link>
        <div className={`navbar-buttons ${isMobile ? "show" : "hide"}`}>
          <Link to="/" onClick={closeMobileMenu}>Home</Link>

          <div
            className="services-dropdown"
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <Link to="/services" onClick={closeMobileMenu}>Services <IoMdArrowDropdown /></Link>
            {showDropdown && (
              <div className="dropdown-menu12">
                {services.map((service, index) => (
                  <Link
                    key={service.id}
                    to={`/${formatTitleToURL(service.title)}`}
                    onClick={closeMobileMenu}
                  >
                    <AiOutlineCaretRight /> {service.title}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link to="/about" onClick={closeMobileMenu}>About</Link>
          <Link to="/blogs" onClick={closeMobileMenu}>Blogs</Link>
          <Link to="/contact" onClick={closeMobileMenu}>Contact Us</Link>
          <Link to="/pitch-deck" onClick={handlePdfOpen}>Pitch Deck</Link>
        </div>
        <div
          className="navbar-talk"
          onClick={() => window.tidioChatApi && window.tidioChatApi.open()}
        >
          <FaRegDotCircle className="talk-icon" />
          <span>Let's Talk!</span>
        </div>
        <div className="navbar-mobile-menu" onClick={handleMobileMenu}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;




// import React, { useState, useEffect } from 'react';
// import { FaRegDotCircle, FaBars, FaTimes } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
// import compImage from '../images/rightimage.png';
// import deckPdf from '../images/Web5 Pitch Deck (1).pdf'
// import './Navbar.css';

// const Navbar = () => {
//     const [isMobile, setIsMobile] = useState(false);
//     const [animate, setAnimate] = useState(false);

//     useEffect(() => {
//         const animateCycle = () => {
//             setAnimate(true);
//             setTimeout(() => {
//                 setAnimate(false);
//             }, 5000);
//         };

//         // Initial cycle
//         const initialTimer = setTimeout(animateCycle, 2000);
//         const interval = setInterval(animateCycle, 7000);

//         return () => {
//             clearTimeout(initialTimer);
//             clearInterval(interval);
//         };
//     }, []);

//     const handleMobileMenu = () => {
//         setIsMobile(!isMobile);
//     };

//     const closeMobileMenu = () => {
//         setIsMobile(false);
//     };

//     const handleTidioOpen = () => {
//         if (window.tidioChatApi) {
//             window.tidioChatApi.open();
//         }
//     };
//     const handlePdfOpen = (e) => {
//         e.preventDefault(); // Prevent default Link behavior
//         window.open(deckPdf, '_blank'); // Open the PDF in a new tab
//         closeMobileMenu(); // Close the mobile menu after the click
//     };

//     return (
//         <nav className="navbar">
//             <div className="navbar-container">
//                 <Link to="/" className={`navbar-image ${animate ? 'animate' : ''}`} onClick={closeMobileMenu}>
//                     <img src={compImage} alt='' />
//                 </Link>
//                 <div className={`navbar-buttons ${isMobile ? 'show' : 'hide'}`}>
//                     <Link to="/" onClick={closeMobileMenu}>Home</Link>
//                     <Link to="/services" onClick={closeMobileMenu}>Services</Link>
//                     <Link to="/about" onClick={closeMobileMenu}>About</Link>
//                     <Link to="/blogs" onClick={closeMobileMenu}>Blogs</Link>
//                     <Link to="/contact" onClick={closeMobileMenu}>Contact Us</Link>
//                     <Link to="/pitch-deck" onClick={handlePdfOpen} >Pitch Deck</Link>
//                 </div>
//                 <div className="navbar-talk" onClick={handleTidioOpen}>
//                     <FaRegDotCircle className="talk-icon" />
//                     <span>Let's Talk!</span>
//                 </div>
//                 <div className="navbar-mobile-menu" onClick={handleMobileMenu}>
//                     {isMobile ? <FaTimes /> : <FaBars />}
//                 </div>
//             </div>
//         </nav>
//     );
// };

// export default Navbar;
