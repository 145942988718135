import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import axios from "axios";
import chatbotlogo from '../src/images/rightimage.png';

const ChatBot = () => {
  const [messages, setMessages] = useState([
    { text: "Welcome to Web5 Solutions! Can I assist you on your journey?", sender: "bot", options: ["Yes", "No"] },
  ]);
  const [userInput, setUserInput] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("initial");
  const [userData, setUserData] = useState({});
  
  // Ref for the messages container
  const messagesEndRef = useRef(null);

  const addMessage = (text, sender, options = null) => {
    setMessages((prevMessages) => [...prevMessages, { text, sender, options }]);
  };

  const handleUserResponse = (response) => {
    const newUserData = { ...userData };

    if (currentQuestion === "initial") {
      if (response.toLowerCase() === "yes") {
        addMessage("Great! What's your name?", "bot");
        setCurrentQuestion("askingName");
      } else {
        addMessage("No problem! Feel free to ask if you need any help.", "bot");
        setCurrentQuestion("end");
      }
    } else if (currentQuestion === "askingName") {
      if (!/^[a-zA-Z\s]+$/.test(response)) {
        addMessage("Please enter a valid name (letters and spaces only).", "bot");
        return;
      }
      newUserData.name = response;
      setUserData(newUserData);
      addMessage("Nice to meet you! What's your email?", "bot");
      setCurrentQuestion("askingEmail");
    } else if (currentQuestion === "askingEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(response)) {
        addMessage("Please enter a valid email address.", "bot");
        return;
      }
      newUserData.email = response;
      setUserData(newUserData);
      addMessage("Thanks! Can you provide your phone number?", "bot");
      setCurrentQuestion("askingPhone");
    } else if (currentQuestion === "askingPhone") {
      if (!/^\d{10,15}$/.test(response)) {
        addMessage("Please enter a valid phone number (10-15 digits).", "bot");
        return;
      }
      newUserData.phone = response;
      setUserData(newUserData);
      addMessage("What service are you interested in?", "bot");
      setCurrentQuestion("askingService");
    } else if (currentQuestion === "askingService") {
      newUserData.service = response;
      setUserData(newUserData);
      addMessage("Thank you! We'll review your details and reach out soon.", "bot");
      setCurrentQuestion("end");
      sendToEmailAndWhatsApp(newUserData);
    }
  };

  const sendToEmailAndWhatsApp = (data) => {
    const { name, email, phone, service } = data;

    const emailParams = {
      to_name: "Web5 Solutions Support",
      name: name,
      email: email,
      phone: phone,
      service: service,
      query: "No additional information provided.",
    };

    emailjs.send("service_5proq6w", "template_x7eipfo", emailParams, "AWvxc47mipQeOEPBL")
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });

    const twilioData = new URLSearchParams({
      To: "whatsapp:+918950629291",
      From: "whatsapp:+14155238886",
      Body: `
        New inquiry from Web5 Solutions Chatbot:
        
        - Name: ${name}
        - Email: ${email}
        - Phone: ${phone}
        - Interested Service: ${service}
      `,
    });

    axios.post(
      "https://api.twilio.com/2010-04-01/Accounts/AC474fa620333fb66842e1013e01916793/Messages.json",
      twilioData,
      {
        auth: {
          username: "AC474fa620333fb66842e1013e01916793",
          password: "86fb33dd51b83599b7d49fa2a5a9b5e9",
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    ).then((response) => {
      console.log("WhatsApp message sent successfully:", response.data);
    }).catch((error) => {
      console.error("Error sending WhatsApp message:", error);
    });
  };

  const handleOptionClick = (option) => {
    addMessage(option, "user");
    handleUserResponse(option);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    addMessage(userInput, "user");
    handleUserResponse(userInput);
    setUserInput("");
  };

  // Scroll to the latest message when `messages` changes
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <img src={chatbotlogo} alt="Web5 Solutions Logo" className="chatbot-logo"/>
        <h4>Web5 Solution</h4>
        <p>We're here to provide expert guidance every step of the way.</p>
      </div>
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.sender === "bot" && (
              <div className="bot-icon">W</div>
            )}
            <div className={msg.sender === "bot" ? "bot-message-bubble" : "user-message"}>
              {msg.text}
              {msg.options && (
                <div className="options">
                  {msg.options.map((option, i) => (
                    <button key={i} onClick={() => handleOptionClick(option)}>
                      {option}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
        {/* Reference element for scrolling */}
        <div ref={messagesEndRef} />
      </div>
      {currentQuestion !== "end" && (
        <form onSubmit={handleSubmit} className="input-container">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type a message..."
          />
          <button type="submit">Send</button>
        </form>
      )}
    </div>
  );
};

export default ChatBot;
