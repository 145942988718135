import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for dynamic title and metadata
import blogsData from '../data/blogs.json';
import BlogSection from './BlogSection';
import { slugify } from '../utils/slugify';

const BlogDetails = () => {
  const { slug } = useParams();

  const blog = blogsData.blogs.find(blog => slugify(blog.title) === slug);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const { title, description, image, date, content } = blog;

  // Function to truncate the description to 120 characters
  const truncateDescription = (text, maxLength = 120) => {
    if (!text || typeof text !== 'string') {
      return '';
    }
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const truncatedDescription = truncateDescription(description);

  return (
    <>
      <Helmet>
        <title>{title} - Web5Solution</title>
        <meta property="description" content={truncatedDescription} />
        {console.log('truncatedDescription ===>>>',truncatedDescription)}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={truncatedDescription} />
        <meta property="og:image" content={require(`../images/${image}`)} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      {/* BlogSection Component */}
      <BlogSection
        title={title}
        description={description}
        image={image}
        date={date}
        content={content}
      />
    </>
  );
};

export default BlogDetails;
