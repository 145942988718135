
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./WhatOffer.css";
import customImage from "../images/Custom_Blockchain_Technology_3.jpg";
import nftImage from "../images/NFT 1.jpg";
import crowdImage from "../images/CROWD FUNDING.jpg";
import blockImage from "../images/Blockchain Payment Platform.png";
import finalImage from "../images/Financial Trading Platform.png";
import marketImage from "../images/Marketplace Development.png";
import daoImage from "../images/DAO Development.png";
import defiImage from "../images/DeFi Development.png";
import gameImage from "../images/GameFi Apps.png";
import bitImage from "../images/bitcoin.gif";
import servicesData from "../data/services2.json";
import CustomButton from "./CustomButton";
import imageImage from '../images/Frame.svg';
const Offer = () => {
  const [showAllCards, setShowAllCards] = useState(false);
  const navigate = useNavigate();

  // Fetch service title based on card ID
  const getServiceTitleById = (id) => {
    const service = servicesData.services.find(
      (service) => service.id === id.toString()
    );
    return service ? service.title : null;
  };

  const handleShowMoreOrLess = () => {
    setShowAllCards(!showAllCards);
  };

  const handleCardClick = (id) => {
    const title = getServiceTitleById(id); 
    if (title) {
      const formattedTitle = title.toLowerCase().replace(/ /g, "-"); 
      navigate(`/services/${formattedTitle}`);
    } else {
      console.error(`Service with id ${id} not found.`);
    }
  };

  const renderCards = () => {
    const cards = [
      {
        id: 1,
        leftHeader: "01 Blockchain Development Company",
        description:
          "Looking to build your own blockchain application from the ground up? Web5Solution is here to help! We specialize ",
        fullDescription:
          "Looking to build your own blockchain application from the ground up? Web5Solution is here to help! We specialize in creating unique blockchain solutions tailored to your....",
        image: customImage,
      },
      {
        id: 2,
        leftHeader: "02 NFT Development",
        description:
          "Looking to build your own NFT marketplace from the ground up? Web5Solution is here to help! We specialize in creating",
        fullDescription:
          "Looking to build your own NFT marketplace from the ground up? Web5Solution is here to help! We specialize in creating unique NFT solutions tailored to your needs....",
        image: nftImage,
      },
      {
        id: 3,
        leftHeader: "03 Smart Contract Development Company",
        description:
          "Looking to build your own smart contracts from the ground up? Web5Solution is here to help.....",
        fullDescription:
          "Looking to build your own smart contracts from the ground up? Web5Solution is here to help! We specialize in creating unique smart contract solutions tailored to your needs.....",
        image: crowdImage,
      },
      {
        id: 4,
        leftHeader: "04 Crypto Exchange Development Company",
        description:
          "Looking to launch your own cryptocurrency exchange from the ground up? Web5Solution is here to help....",
        fullDescription:
          "Looking to launch your own cryptocurrency exchange from the ground up? Web5Solution is here to help! We specialize in creating unique crypto exchange platforms tailored to your needs.....",
        image: blockImage,
      },
      {
        id: 5,
        leftHeader: "05 Metaverse Development Company",
        description:
          "Looking to build your own Metaverse application from the ground up? Web5Solution is here to help....",
        fullDescription:
          "Looking to build your own Metaverse application from the ground up? Web5Solution is here to help! We specialize in creating unique Metaverse solutions tailored to your needs....",
        image: finalImage,
      },
      {
        id: 6,
        leftHeader: "06 Real World Asset Tokenization Development Service",
        description:
          "Looking to tokenize real-world assets on the blockchain? Web5Solution is here to help....",
        fullDescription:
          "Looking to tokenize real-world assets on the blockchain? Web5Solution is here to help! We specialize in transforming physical assets into digital tokens.....",
        image: marketImage,
      },
      {
        id: 7,
        leftHeader: "07 DAO Development Company",
        description:
          "Our DAO Development services create decentralized autonomous organizations....",
        fullDescription:
          "Our DAO Development services create decentralized autonomous organizations that enable transparent, democratic governance. We design and implement smart contracts....",
        image: daoImage,
      },
      {
        id: 8,
        leftHeader: "08 DeFi Development",
        description:
          "Our DeFi Development solutions revolutionize financial services by enabling decentralized lending....",
        fullDescription:
          "Our DeFi Development solutions revolutionize financial services by enabling decentralized lending, borrowing, and trading. We build secure, scalable platforms....",
        image: defiImage,
      },
      {
        id: 9,
        leftHeader: "09 GameFi Apps",
        description:
          "Our GameFi App development integrates blockchain technology with gaming, creating play-to-earn models....",
        fullDescription:
          "Our GameFi App development integrates blockchain technology with gaming, creating play-to-earn models. We design immersive, rewarding gaming experiences....",
        image: gameImage,
      },
    ];
  
    const cardsToRender = showAllCards ? cards : cards.slice(0, 3);
  
    return cardsToRender.map((card, index) => (
      <div
        key={index}
        className="offer-content"
        onClick={() => handleCardClick(card.id)}
      >
        {card.id % 2 === 0 ? (
          <>
            <div className={`offer-right${card.id % 2 === 0 ? "-2" : ""}`}>
              {/* <h3 className="offer-right-header"> {card.leftHeader}</h3> */}
              <p className="offer-right-description">
                <span className="mobile-view">{card.description}</span>
                <span className="desktop-view">{card.fullDescription}</span>
                <button >Know More<img src={imageImage} style={{
    filter: "invert(34%) sepia(100%) saturate(500%) hue-rotate(130deg) brightness(95%) contrast(90%)", width:"18px"
  }}/></button>
              </p>
              <div className="image-wrapper">
                <img
                  src={card.image}
                  alt="Hover to enlarge"
                  className="image"
                />
              </div>
            </div>
            <div className="offer-left-01">
              <p className="offer-left-header">{card.leftHeader}</p>
            </div>
            <img src={bitImage} alt="" className="bitcoin" />
          </>
        ) : (
          <>
            <div className="offer-left">
              <p className="offer-left-header">{card.leftHeader}</p>
            </div>
            <div className={`offer-right${card.id % 2 === 0 ? "-2" : ""}`}>
              {/* <h3 className="offer-right-header">{card.leftHeader}</h3> */}
              <p className="offer-right-description">
                <span className="mobile-view">{card.description}</span>
                <span className="desktop-view">{card.fullDescription}</span>
                <button >Know More<img src={imageImage} style={{
    filter: "invert(34%) sepia(100%) saturate(500%) hue-rotate(130deg) brightness(95%) contrast(90%)", width:"18px"
  }}/> </button>
              </p>
              <div className="image-wrapper">
                <img
                  src={card.image}
                  alt="Hover to enlarge"
                  className="image"
                />
              </div>
            </div>
          </>
        )}
        {index === cardsToRender.length - 1 ? (
          // Custom SVG for the last card
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="641"
            height="140"
            viewBox="0 0 641 163"
            fill="none"
            className="unique-svg-class"

          >
            <path
              d="M1 1C18.1832 162 378.493 31.119 640 162"
              stroke="black"
              strokeDasharray="2 2"
            />
          </svg>
        ) : (
          index % 2 === 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1000"
              height="163"
              viewBox="0 0 1161 163"
              fill="none"
              className="offer-content-svg"
            >
              <path
                d="M0.858459 1C32.0329 162 685.721 31.119 1160.16 162"
                stroke="black"
                strokeDasharray="2 2"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1000"
              height="163"
              viewBox="0 0 1147 163"
              fill="none"
              className="offer-content-svg"
            >
              <path
                d="M1146 1C1115.21 162 469.584 31.119 1.00003 162"
                stroke="black"
                strokeDasharray="2 2"
              />
            </svg>
          )
        )}
      </div>
    ));
  };
  

  return (
    <div className="offer-component">
      <div className="offer-header">
        <h2>Innovative Blockchain Services</h2>
      </div>
      <div className="offer-subheader">
        <p>Revolutionize Your Digital Odyssey with Web5 Solution</p>
      </div>
      {renderCards()}
      <div className="whatOffer-btn">
        <CustomButton
          onClick={handleShowMoreOrLess}
          showAllCards={showAllCards}
          buttonText="Know More"
        />
      </div>
    </div>
  );
};

export default Offer;










// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./WhatOffer.css";
// import customImage from "../images/Custom_Blockchain_Technology_3.jpg";
// import nftImage from "../images/NFT 1.jpg";
// import crowdImage from "../images/CROWD FUNDING.jpg";
// import blockImage from "../images/Blockchain Payment Platform.png";
// import finalImage from "../images/Financial Trading Platform.png";
// import marketImage from "../images/Marketplace Development.png";
// import daoImage from "../images/DAO Development.png";
// import defiImage from "../images/DeFi Development.png";
// import gameImage from "../images/GameFi Apps.png";
// import bitImage from "../images/bitcoin.gif";

// // Import services.json (assuming it's in the /data folder)
// import servicesData from "../data/services.json";

// import CustomButton from "./CustomButton";

// const Offer = () => {
//   const [showAllCards, setShowAllCards] = useState(false);
//   const navigate = useNavigate();

//   // Fetch service title based on card ID
//   const getServiceTitleById = (id) => {
//     const service = servicesData.services.find(
//       (service) => service.id === id.toString()
//     );
//     return service ? service.title : null;
//   };

//   const handleShowMoreOrLess = () => {
//     setShowAllCards(!showAllCards);
//   };

//   const handleCardClick = (id) => {
//     const title = getServiceTitleById(id); // Fetch title by ID
//     if (title) {
//       const formattedTitle = title.toLowerCase().replace(/ /g, "-"); // Convert title to URL-friendly format
//       navigate(`/services/${formattedTitle}`); // Navigate to the service page based on the title
//     } else {
//       console.error(`Service with id ${id} not found.`);
//     }
//   };

//   const renderCards = () => {
//     const cards = [
//       {
//         id: 1,
//         leftHeader: "01 Custom Blockchain Technology Solutions We Deliver",
//         description:
//           "Looking to build your own blockchain application from the ground up? Web5Solution is here to help! We specialize ",
//         fullDescription:
//           "Looking to build your own blockchain application from the ground up? Web5Solution is here to help! We specialize in creating unique blockchain solutions tailored to your....",
//         image: customImage,
//       },
//       {
//         id: 2,
//         leftHeader: "02 NFT Development",
//         description:
//           "Looking to build your own NFT marketplace from the ground up? Web5Solution is here to help! We specialize in creating",
//         fullDescription:
//           "Looking to build your own NFT marketplace from the ground up? Web5Solution is here to help! We specialize in creating unique NFT solutions tailored to your needs....",
//         image: nftImage,
//       },
//       {
//         id: 3,
//         leftHeader: "03 Crowdfunding Platform Development",
//         description:
//           "We build custom crowdfunding platforms that streamline fundraising processes. Our solutions enable secure....",
//         fullDescription:
//           "We build custom crowdfunding platforms that streamline fundraising processes. Our solutions enable secure, transparent, and efficient capital raising....",
//         image: crowdImage,
//       },
//       {
//         id: 4,
//         leftHeader: "04 Blockchain Payment Platform",
//         description:
//           "Our Blockchain Payment Platforms facilitate secure, instant transactions. We develop scalable solutions that integrate....",
//         fullDescription:
//           "Our Blockchain Payment Platforms facilitate secure, instant transactions. We develop scalable solutions that integrate seamlessly with existing systems....",
//         image: blockImage,
//       },
//       {
//         id: 5,
//         leftHeader: "05 Financial Trading Platform",
//         description:
//           "Our Financial Trading Platforms provide advanced, secure environments for trading digital assets....",
//         fullDescription:
//           "Our Financial Trading Platforms provide advanced, secure environments for trading digital assets. We design user-friendly interfaces and implement....",
//         image: finalImage,
//       },
//       {
//         id: 6,
//         leftHeader: "06 Marketplace Development",
//         description:
//           "We specialize in developing decentralized marketplaces that connect buyers and sellers directly....",
//         fullDescription:
//           "We specialize in developing decentralized marketplaces that connect buyers and sellers directly. Our solutions ensure secure, transparent transactions....",
//         image: marketImage,
//       },
//       {
//         id: 7,
//         leftHeader: "07 DAO Development",
//         description:
//           "Our DAO Development services create decentralized autonomous organizations....",
//         fullDescription:
//           "Our DAO Development services create decentralized autonomous organizations that enable transparent, democratic governance. We design and implement smart contracts....",
//         image: daoImage,
//       },
//       {
//         id: 8,
//         leftHeader: "08 DeFi Development",
//         description:
//           "Our DeFi Development solutions revolutionize financial services by enabling decentralized lending....",
//         fullDescription:
//           "Our DeFi Development solutions revolutionize financial services by enabling decentralized lending, borrowing, and trading. We build secure, scalable platforms....",
//         image: defiImage,
//       },
//       {
//         id: 9,
//         leftHeader: "09 GameFi Apps",
//         description:
//           "Our GameFi App development integrates blockchain technology with gaming, creating play-to-earn models....",
//         fullDescription:
//           "Our GameFi App development integrates blockchain technology with gaming, creating play-to-earn models. We design immersive, rewarding gaming experiences....",
//         image: gameImage,
//       },
//     ];

//     const cardsToRender = showAllCards ? cards : cards.slice(0, 3);

//     return cardsToRender.map((card, index) => (
//       <div
//         key={index}
//         className="offer-content"
//         onClick={() => handleCardClick(card.id)}
//       >
//         {card.id % 2 === 0 ? (
//           <>
//             <div className={`offer-right${card.id % 2 === 0 ? "-2" : ""}`}>
//               <h3 className="offer-right-header"> {card.leftHeader}</h3>
//               <p className="offer-right-description">
//                 <span className="mobile-view">{card.description}</span>
//                 <span className="desktop-view">{card.fullDescription}</span>
//               </p>
//               <div className="image-wrapper">
//                 <img
//                   src={card.image}
//                   alt="Hover to enlarge"
//                   className="image"
//                 />
//               </div>
//             </div>
//             <div className="offer-left-01">
//               <p className="offer-left-header">{card.leftHeader}</p>
//             </div>
//             <img src={bitImage} alt="" className="bitcoin" />
//           </>
//         ) : (
//           <>
//             <div className="offer-left">
//               <p className="offer-left-header">{card.leftHeader}</p>
//             </div>
//             <div className={`offer-right${card.id % 2 === 0 ? "-2" : ""}`}>
//               <h3 className="offer-right-header">

//                 {card.leftHeader}
//               </h3>
//               <p className="offer-right-description">
//                 <span className="mobile-view">{card.description}</span>
//                 <span className="desktop-view">{card.fullDescription}</span>
//               </p>
//               <div className="image-wrapper">
//                 <img
//                   src={card.image}
//                   alt="Hover to enlarge"
//                   className="image"
//                 />
//               </div>
//             </div>
//           </>
//         )}
//         {index % 2 === 0 ? (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="1000"
//             height="163"
//             viewBox="0 0 1161 163"
//             fill="none"
//           >
//             <path
//               d="M0.858459 1C32.0329 162 685.721 31.119 1160.16 162"
//               stroke="black"
//               strokeDasharray="2 2"
//             />
//           </svg>
//         ) : (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="1000"
//             height="163"
//             viewBox="0 0 1147 163"
//             fill="none"
//           >
//             <path
//               d="M1146 1C1115.21 162 469.584 31.119 1.00003 162"
//               stroke="black"
//               strokeDasharray="2 2"
//             />
//           </svg>
//         )}
//       </div>
//     ));
//   };

//   return (
//     <div className="offer-component">
//       <div className="offer-header">
//         <h2>Innovative Blockchain Services</h2>
//       </div>
//       <div className="offer-subheader">
//         <p>Revolutionize Your Digital Odyssey with Web5 Solution</p>
//       </div>
//       {renderCards()}
//       <div className="whatOffer-btn">
//         <CustomButton
//           onClick={handleShowMoreOrLess}
//           showAllCards={showAllCards}
//           buttonText="Know More"
//         />
//       </div>
//     </div>
//   );
// };

// export default Offer;