import React from 'react';
import './HowWorks.css'; 
import HowWorksCards from './HowWorksCards'; 
import HowWorksCards2 from './HowWorksCards2';
import image35Image from '../images/247_support_450_x_326_px.webp';
import image36Image from '../images/SCALABILTY 4.jpg'; 
import image39Image from '../images/client centric 2.jpg'; 
import image40Image from '../images/CS 1.jpg';

const HowWorks = () => {

  const cardsData1 = [
    {
      title: '24/7 Availability with Quick Turnaround',
      subtitle: '01-24/7 Availability with Quick Turnaround',
      content: 'At Web5 Solutions, we ensure round-the-clock availability and rapid response times. Our commitment to timely support and efficient problem-solving guarantees that your projects stay on track, meeting deadlines and exceeding expectations.',
      image: image35Image,
    },

  ];

  const cardsData2 = [
    {
      title: 'Flexibility and Scalability',
      subtitle: '02-Flexibility and Scalability',
      content: 'Web5 Solutions offers unparalleled flexibility and scalability, adapting to your evolving business needs. Whether you’re expanding or pivoting, our solutions grow with you, ensuring seamless transitions and sustained growth.',
      image: image36Image,
    },
  ];
  const cardsData3=[
    {
      title: 'Client-Centric Approach',
      subtitle: '03-Client-Centric Approach',
      content: 'Our client-centric approach places your needs at the forefront. We prioritize understanding your business goals and challenges, delivering tailored solutions that drive success and foster long-term partnerships.',
      image: image39Image
    }
  ];
  const cardsData4=[
    {
      title: 'Creative and Strategic Involvement',
      subtitle: '04-Creative and Strategic Involvement',
      content: 'Web5 Solutions excels in combining creativity with strategic insight. Our team collaborates closely with you, leveraging innovative thinking and strategic planning to deliver impactful, high-quality solutions that stand out in the market.',
      image: image40Image,
    }
  ];

  return (
    <div className="hows-card-container">
      <h2 className='how-heading'>Lead the Future with Our Complete Service
      Solutions</h2>
      {cardsData1.map((card, index) => (
        <HowWorksCards key={index} {...card} index={index} />
      ))}
      {cardsData2.map((card, index) => (
        <HowWorksCards2 key={index + cardsData1.length} {...card} index={index + cardsData1.length} />
      ))}
      {cardsData3.map((card, index) => (
        <HowWorksCards key={index} {...card} index={index} />
      ))}
      {cardsData4.map((card, index) => (
        <HowWorksCards2 key={index + cardsData1.length} {...card} index={index + cardsData1.length} />
      ))}
    </div>
  );
};

export default HowWorks;
