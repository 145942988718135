import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize('G-PYTC67RM86');
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />
 
);

reportWebVitals();
