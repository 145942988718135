import React from 'react'
import './OurMission.css'
import rectangle70Image from '../images/imagemission.jpg';
import CustomButton from './CustomButton';

const OurMission = () => {
  return (
    <div className='ourMission'>
        <h3>Our Mission</h3>
        <div className='ourMission-main-card'>
        <div className='ourMission-card1'>
      <h4>We are helping businesses with Web3 solutions.</h4>
      <p>Our mission is to empower businesses through innovative blockchain solutions, transforming complex challenges into streamlined, automated processes that enhance productivity and growth. We are dedicated to continuously learning, applying, and creating new technologies to solve real-world problems. Whether it's developing a secure NFT marketplace or crafting a seamless mobile app experience, we approach each project with creativity, strategic thinking, and technical precision. </p>
      
      <div className='ourMissionbtn'>
      <CustomButton buttonText="Know More" />
      </div>
      </div>
   
      <div className='ourMission-card2'>
      <img src={rectangle70Image} alt=''/>
      </div>
      </div>
    </div>
  )
}

export default OurMission
