import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; 
import WhoWeAre2 from './WhoWeAre2';
import OurTeam from './OurTeam';
import LatestBlogs from './LatestBlogs';
import './ServiceInternalPage.css';
import CustomButton from './CustomButton';
import RelatedServices from './RelatedServices';
import StudyCase from './StudyCase';
import { Helmet } from 'react-helmet';
const servicesData = require('../data/services2.json'); 

const ServiceInternalPage2 = () => {
  const { serviceTitle } = useParams(); 
  const navigate = useNavigate(); 
  const [selectedService, setSelectedService] = useState(null);

  const handleClick = () => {
    navigate('/contact');
  };
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, [serviceTitle]);

  useEffect(() => {
    const formattedTitle = serviceTitle.replace(/-/g, ' ').toLowerCase();

    console.log('Available services:', servicesData.services.map(service => service.title.toLowerCase()));

    const service = servicesData.services.find(service =>
      service.title.toLowerCase() === formattedTitle
    );
    console.log('services for SIP2',service)


    if (service) {
      setSelectedService(service);
    } else {
      console.error(`Service with title "${formattedTitle}" not found`);
      navigate('/404', { replace: true }); 
    }
  }, [serviceTitle, navigate]);

  if (!selectedService) {
    return <div>Loading...</div>; 
  }
  const truncateDescription = (text, maxLength = 120) => {
    if (!text || typeof text !== 'string') {
      return '';
    }
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const truncatedDescription = truncateDescription(selectedService.meta_description);

  return (
    <div>
      <Helmet>
        <title>{selectedService.meta_title} - Web5Solution</title>
        <meta property="description" content={truncatedDescription} />
        {console.log('truncatedDescription ===>>>',)}
        <meta property="og:title" content={selectedService.meta_title} />
        <meta property="og:description" content={truncatedDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className='serviceInternal-header' 
      style={{
       backgroundImage: `url(${require(`../images/${selectedService.introduction.hedaerImage}`)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      >
        <div className='serviceInternal-content'>
          <h1 style={{fontSize: "2.2rem"}}>{selectedService.title}</h1>
          {console.log('Selected Service title == >> ', selectedService.title)}
          <p>{selectedService.introduction.description}</p>
          <CustomButton buttonText='Let’s Chat'  onClick={handleClick} />
        </div>
{/* 
        <div className='serviceInternal-Image'>
          {selectedService.introduction.hedaerImage && (
            <img
              src={require(`../images/${selectedService.introduction.hedaerImage}`)}
              alt={selectedService.title}
            />
          )}
        </div> */}
      </div>

      <div className='serviceInternal-div'>
        <div className='serviceImage-internal-div'>
          {selectedService.detailed_description.detailed_descriptionImage && (
            <img
              src={require(`../images/${selectedService.detailed_description.detailed_descriptionImage}`)}
              className='serviceImage-internal'
              alt='Service'
              loading='lazy'
            />
          )}
        </div>
        <div className='service-internal-content2'>
          <h2 style={{marginBottom: "20px"}}>{selectedService.title1}</h2>
          {selectedService.detailed_description.description
  .split('\n\n') 
  .map((paragraph, index) => (
    <p key={index}>
      {paragraph.split('\n').map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>
  ))}
        </div>
      </div>

      <RelatedServices servicesOffered={selectedService.services_offered} />
      <OurTeam />
      <WhoWeAre2 chooseUs={selectedService.why_choose_us}/>
      <StudyCase useCases={selectedService.use_cases} />
      <LatestBlogs />
    </div>
  );
};

export default ServiceInternalPage2;
