import React from 'react';
import './OurTeam.css';
import collectionImage from '../images/collection.svg';
import documentImage from '../images/document.svg';
import teamImage from '../images/team.svg';
import partnershipImage from '../images/partnership.svg';
import powerImage from '../images/power.svg';
import OurTeamCard from './OurTeamCard';

const ourTeam = [
    { title: "Gather Ideas",  image: collectionImage , paragraph:"We take the time to fully understand your vision and goals, ensuring alignment from the start."},
    { title: "Draft a Plan",  image: documentImage, paragraph:"We develop a comprehensive project plan that outlines each step needed to bring your concept to life." },
    { title: "Form the Team",  image: teamImage, paragraph:"We assemble a team of top experts, specifically chosen to meet the unique demands of your project." },
    { title: "Work Together",  image: partnershipImage, paragraph:"We coordinate seamlessly across all team members, ensuring collaboration and consistent progress." },
    { title: "Start Creating",  image: powerImage, paragraph:"With everything in place, we initiate the project, transforming your vision into a successful reality." },
  ];

function OurTeam() {
  return (
    <div className='ourTeam'>
  <h3>How We Set Up Our Team</h3>
  <h4>Revolutionize Your Digital Odyssey with Web5 Solution</h4>
  <div className="our-team-card-grid">
    {ourTeam.map((service, index) => (
      <OurTeamCard
        key={index}
        title={service.title}
        image={service.image}
        showSvg={index < 4}
        para={service.paragraph}
      />
    ))}
  </div>
</div>
  )
}

export default OurTeam