import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Cards.css';

const Cards = ({ title1, title, description, image, route }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (route) {
      const formattedTitle = route?.toLowerCase().replace(/\s+/g, '-');
      navigate(`/${formattedTitle}`);
    } else {
      navigate('/services');
    }
  };

  return (
    <div className="card-container" onClick={handleCardClick}>
      <div className="service-card">
        <div className="card-image-container">
          <img src={image} alt={title1 || title} className="card-image" />
        </div>
        <div className="card-content">
          <h3>{title1 || title}</h3> 
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
