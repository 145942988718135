import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ServicesSection.css';
import servicesData from '../data/services2.json';

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
   
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
     
      onClick={onClick}
    />
  );
};

const ServicesSection = () => {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setServices(servicesData.services);
  }, []);

  const handleCardClick = (title) => {
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();
    navigate(`/services/${formattedTitle}`);
    console.log('formattedTitle SS=== ',formattedTitle)
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  };

  return (
    <section className="unique-services-section">
      <h2>Our Services</h2>
      <Slider {...settings}>
        {services.map((service, index) => (
          <div key={index} className="unique-card-container" onClick={() => handleCardClick(service.title)}>
            <div className="unique-card">
              <div className="unique-front-content">
                <img
                src={require(`../images/${service.introduction.headerImage2}`)}
                alt={service.title} className="unique-service-image" />
                <h3>{service.title}</h3>
              </div>
              <div className="unique-content">
                <p className="unique-heading">{service.title}</p>
                <p>{service.introduction.description2}</p>
                <a href="#" className="unique-know-more-link">Know More ↗</a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default ServicesSection;
