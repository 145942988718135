import React from 'react';
import './BlogCards.css';

const BlogCards = ({ title, description, image, date ,onClick}) => {
  return (
    <div className="blogCard" onClick={onClick}>
      <div className="blogCard-image-container">
        <img src={image} alt={title} className="blogCard-image" />
      </div>
      <div className="blogCard-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <p>{date}</p>
      </div>
    </div>
  );
};

export default BlogCards;
