import React, { useEffect, useState } from "react";
import axios from "axios";

const TwilioDataTable = () => {
  const [messages, setMessages] = useState([]);
  console.log("message for Twilio component === ", messages);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.twilio.com/2010-04-01/Accounts/AC6f001c6c7212333d8b47d36bc38f5b47/Messages.json",
          {
            auth: {
              username: "AC6f001c6c7212333d8b47d36bc38f5b47",
              password: "4f9fdf3b28a58e24655593d81161a6e0",
            },
          }
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching Twilio messages:", error);
      }
    };

    fetchData();
  }, []);

  // Handle deletion of a specific message
  const handleOnClick = async (sid) => {
    try {
      // Send DELETE request to delete message from Twilio
      await axios.delete(
        `https://api.twilio.com/2010-04-01/Accounts/AC6f001c6c7212333d8b47d36bc38f5b47/Messages/${sid}.json`,
        {
          auth: {
            username: "AC6f001c6c7212333d8b47d36bc38f5b47",
            password: "4f9fdf3b28a58e24655593d81161a6e0",
          },
        }
      );
      console.log("Message deleted successfully");

      // Update the messages state by removing the deleted message
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.sid !== sid)
      );
    } catch (error) {
      console.error("Failed to delete message:", error);
    }
  };

  const extractDataFromBody = (body) => {
    const data = {};
    const lines = body.split("\n");
    lines.forEach((line) => {
      if (line.includes("Name: ")) {
        data.name = line.replace("Name: ", "").trim();
      } else if (line.includes("Email: ")) {
        data.email = line.replace("Email: ", "").trim();
      } else if (line.includes("Phone: ")) {
        data.phone = line.replace("Phone: ", "").trim();
      } else if (line.includes("Project: ")) {
        data.project = line.replace("Project: ", "").trim();
      } else if (line.includes("Service: ")) {
        data.service = line.replace("Service: ", "").trim();
      }
    });
    return data;
  };

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <h2>Visitors</h2>
      <table
        border="1"
        cellPadding="8"
        cellSpacing="0"
        style={{ width: "80%", margin: "20px auto" }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Project</th>
            <th>Service</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message, index) => {
            const data = extractDataFromBody(message.body);
            return (
              <tr key={index}>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.phone}</td>
                <td>{data.project}</td>
                <td>{data.service}</td>
                <td>
                  {message.date_created
                    ? new Date(message.date_created).toLocaleString("en-GB", {
                        dateStyle: "short",
                        timeStyle: "short",
                      })
                    : "N/A"}
                </td>
                <td>
                  <button onClick={() => handleOnClick(message.sid)}>
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TwilioDataTable;
