import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';
import CustomButton from './CustomButton';
import successImage from "../images/Successfully.gif"

const Modal = ({ show, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000); 

      return () => clearTimeout(timer); 
    }
  }, [show, onClose]);

  if (!show) return null; 

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <img src={successImage} alt="Success" style={{ width: '150px', height: '150px' }} />
        <p>Details Successfully Submitted! <br/>We will contact you soon.</p>
        <button onClick={onClose} className="close-button">X</button>
      </div>
    </div>
  );
};


const ContactForm = () => {
  const [selectedService, setSelectedService] = useState('Blockchain');
  const [fileName, setFileName] = useState(null);
  const [formDetails, setFormDetails] = useState({
    from_name: '',
    to_email: 'contact@web5solution.com',
    fullname: '',
    email: '',
    phone: '',
    howDidYouHear: '',
    project: ''
  });
  const [showModal, setShowModal] = useState(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const sendEmail = (event) => {
    event.preventDefault();

    const emailParams = {
      ...formDetails,
      selectedService,
      fileName
    };

    emailjs.send('service_5proq6w', 'template_piuk7jw', emailParams, 'AWvxc47mipQeOEPBL')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setFormDetails({
          from_name: '',
          to_email: 'contact@web5solution.com',
          fullname: '',
          email: '',
          phone: '',
          howDidYouHear: '',
          project: ''
        });
        setFileName(null);
        setSelectedService('BLOCKCHAIN');
        setShowModal(true); // Show the modal on success
      }, (error) => {
        console.log('FAILED...', error);
      });
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div className="contact-form">
      <h4>Get in touch with us</h4>
      <p>We provide the best services that you need, if you have anything in mind do let us know. We are here to help you out.</p>
      <div className="contact-service-buttons">
        {['Blockchain', 'AI Services', 'Web Development', 'Mobile App Development'].map((service, index) => (
          <button
            key={index}
            className={selectedService === service ? 'active' : ''}
            onClick={() => handleServiceClick(service)}
          >
            {service}
          </button>
        ))}
      </div>
      <form onSubmit={sendEmail}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Your Name *"
            name="from_name"
            required
            value={formDetails.from_name}
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="Full Name *"
            name="fullname"
            required
            value={formDetails.fullname}
            onChange={handleInputChange}
          />
          <input
            type="email"
            placeholder="Email *"
            name="email"
            required
            value={formDetails.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            placeholder="+91"
            name="phone"
            required
            value={formDetails.phone}
            onChange={handleInputChange}
          />
          <select
            name="howDidYouHear"
            required
            value={formDetails.howDidYouHear}
            onChange={handleInputChange}
          >
            <option value="" disabled>How did you hear about us</option>
            <option value="google">Google</option>
            <option value="friend">Friend</option>
            <option value="advertisement">Advertisement</option>
          </select>
        </div>
        <div className="form-group" style={{ width: '100%' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <textarea
              name="project"
              placeholder="About Project *"
              required
              style={{
                width: '100%',
                padding: '10px',
                boxSizing: 'border-box',
                paddingRight: '40px'
              }}
              value={formDetails.project}
              onChange={handleInputChange}
            ></textarea>
            <label
              className="attachment-label"
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '10px',
                cursor: 'pointer',
              }}
            >
              <input
                type="file"
                name="projectFile"
                style={{ position: 'absolute', top: 0, right: 0, opacity: 0, zIndex: -1 }}
                onChange={handleFileChange}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
                onClick={() => document.getElementsByName("projectFile")[0].click()}
              >
                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
              </svg>
              <span className="attachment-icon">{fileName ? <i className="fa fa-paperclip"></i> : null}</span>
            </label>
          </div>
          {fileName && <p>Selected File: {fileName}</p>}
        </div>
        <div className="privacy-policy">
          <p style={{display:"flex",gap:"10px"}}><input type="checkbox"/>
            By sending this form I confirm that I have read and accept the Privacy Policy</p>
          <CustomButton buttonText="Send Details"/>
        </div>
      </form>

      {/* Render the Modal */}
      <Modal show={showModal} onClose={closeModal} />
    </div>
  );
};

export default ContactForm;




// import React, { useState } from 'react';
// import emailjs from 'emailjs-com';
// import './ContactForm.css';
// import CustomButton from './CustomButton';

// const ContactForm = () => {
//   const [selectedService, setSelectedService] = useState('BLOCKCHAIN');
//   const [fileName, setFileName] = useState(null);
//   const [formDetails, setFormDetails] = useState({
//     from_name: '',
//     to_email: 'contact@web5solution.com', // Set your default "To Email" here
//     fullname: '',
//     email: '',
//     phone: '',
//     howDidYouHear: '',
//     project: ''
//   });

//   const handleServiceClick = (service) => {
//     setSelectedService(service);
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setFileName(file.name);
//     }
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormDetails(prevDetails => ({
//       ...prevDetails,
//       [name]: value
//     }));
//   };

//   const sendEmail = (event) => {
//     event.preventDefault();

//     const emailParams = {
//       ...formDetails,
//       selectedService,
//       fileName
//     };

//     emailjs.send('service_5proq6w', 'template_piuk7jw', emailParams, 'AWvxc47mipQeOEPBL')
//       .then((response) => {
//         console.log('SUCCESS!', response.status, response.text);
//         setFormDetails({
//           from_name: '',
//           to_email: 'contact@web5solution.com',
//           fullname: '',
//           email: '',
//           phone: '',
//           howDidYouHear: '',
//           project: ''
//         });
//         setFileName(null);
//         setSelectedService('BLOCKCHAIN');
//       }, (error) => {
//         console.log('FAILED...', error);
//       });
//   };

//   return (
//     <div className="contact-form">
//       <h4>Get in touch with us</h4>
//       <p>We provide the best services that you need, if you have anything in mind do let us know. We are here to help you out.</p>
//       <div className="contact-service-buttons">
//         {['BLOCKCHAIN', 'AI Services', 'Web Development', 'Mobile App Development'].map((service, index) => (
//           <button
//             key={index}
//             className={selectedService === service ? 'active' : ''}
//             onClick={() => handleServiceClick(service)}
//           >
//             {service}
//           </button>
//         ))}
//       </div>
//       <form onSubmit={sendEmail}>
//         <div className="form-group">
//           <input
//             type="text"
//             placeholder="Your Name *"
//             name="from_name"
//             required
//             value={formDetails.from_name}
//             onChange={handleInputChange}
//           />
//           <input
//             type="text"
//             placeholder="Full Name *"
//             name="fullname"
//             required
//             value={formDetails.fullname}
//             onChange={handleInputChange}
//           />
//           <input
//             type="email"
//             placeholder="Email *"
//             name="email"
//             required
//             value={formDetails.email}
//             onChange={handleInputChange}
//           />
//         </div>
//         <div className="form-group">
//           <input
//             type="tel"
//             placeholder="+91"
//             name="phone"
//             required
//             value={formDetails.phone}
//             onChange={handleInputChange}
//           />
//           <select
//             name="howDidYouHear"
//             required
//             value={formDetails.howDidYouHear}
//             onChange={handleInputChange}
//           >
//             <option value="" disabled>How did you hear about us</option>
//             <option value="google">Google</option>
//             <option value="friend">Friend</option>
//             <option value="advertisement">Advertisement</option>
//           </select>
//         </div>
//         <div className="form-group" style={{ width: '100%' }}>
//           <div style={{ position: 'relative', width: '100%' }}>
//             <textarea
//               name="project"
//               placeholder="About Project *"
//               required
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 boxSizing: 'border-box',
//                 paddingRight: '40px'
//               }}
//               value={formDetails.project}
//               onChange={handleInputChange}
//             ></textarea>
//             <label
//               className="attachment-label"
//               style={{
//                 position: 'absolute',
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 right: '10px',
//                 cursor: 'pointer',
//               }}
//             >
//               <input
//                 type="file"
//                 name="projectFile"
//                 style={{ position: 'absolute', top: 0, right: 0, opacity: 0, zIndex: -1 }}
//                 onChange={handleFileChange}
//               />
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="16"
//                 height="16"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//                 onClick={() => document.getElementsByName("projectFile")[0].click()}
//               >
//                 <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
//               </svg>
//               <span className="attachment-icon">{fileName ? <i className="fa fa-paperclip"></i> : null}</span>
//             </label>
//           </div>
//           {fileName && <p>Selected File: {fileName}</p>}
//         </div>
//         <div className="privacy-policy">
//           <p>By sending this form I confirm that I have read and accept the Privacy Policy</p>
//           <CustomButton buttonText="Send Details"/>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ContactForm;
