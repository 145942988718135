import React, { useEffect } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import './TestimonialCarousel.css';

const testimonialData = [
  {
    avatar: "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg",
    name: "Simonette Lindermann",
    review: "Web5 Solution's team is highly adaptable and skilled in blockchain development. They delivered a robust solution that seamlessly integrated with our existing infrastructure, exceeding our expectations. ",
    type: "Client",
  },
  {
    avatar: "https://img.freepik.com/free-photo/close-up-portrait-young-bearded-man-white-shirt-jacket-posing-camera-with-broad-smile-isolated-gray_171337-629.jpg",
    name: "Merilee Beal",
    review: "The NFT platform developed by Web5 Solution was exactly what we needed. Their expertise in the field and attention to detail helped us launch a project that stands out in the market.",
    type: "Client",
  },
  {
    avatar: "https://img.freepik.com/free-photo/pretty-smiling-joyfully-female-with-fair-hair-dressed-casually-looking-with-satisfaction_176420-15187.jpg",
    name: "Suzi Lankester",
    review: "Web5 Solution's team excelled in developing our DeFi application. Their deep understanding of decentralized finance and their collaborative approach made the entire process smooth and successful ",
    type: "Client",
  },
  {
    avatar: "https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg",
    name: "Simonette Lindermann",
    review: "Web5 Solution's team is highly adaptable and skilled in blockchain development. They delivered a robust solution that seamlessly integrated with our existing infrastructure, exceeding our expectations. ",
    type: "Client",
  },
  {
    avatar: "https://img.freepik.com/free-photo/close-up-portrait-young-bearded-man-white-shirt-jacket-posing-camera-with-broad-smile-isolated-gray_171337-629.jpg",
    name: "Merilee Beal",
    review: "The NFT platform developed by Web5 Solution was exactly what we needed. Their expertise in the field and attention to detail helped us launch a project that stands out in the market.",
    type: "Client",
  },
  {
    avatar: "https://img.freepik.com/free-photo/pretty-smiling-joyfully-female-with-fair-hair-dressed-casually-looking-with-satisfaction_176420-15187.jpg",
    name: "Suzi Lankester",
    review: "Web5 Solution's team excelled in developing our DeFi application. Their deep understanding of decentralized finance and their collaborative approach made the entire process smooth and successful ",
    type: "Client",
  },

];

const TestimonialCarousel = () => {
    useEffect(() => {
        const swiper = new Swiper('.swiper-container', {
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 3,
          loop: true,
          spaceBetween: 183,
          effect: 'coverflow',
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 171,
            modifier: 2,
            slideShadows: false,
          },
          autoplay: { delay: 5000 },
        });
      
        const queryResizer = () => {
          if (window.innerWidth < 724) swiper.params.slidesPerView = 2;
          if (window.innerWidth > 501) swiper.params.slidesPerView = 2;
          if (window.innerWidth > 724) swiper.params.slidesPerView = 2;
          if (window.innerWidth < 501) swiper.params.slidesPerView = 1;
          swiper.update();
        };
      
        window.onresize = queryResizer;
        queryResizer();
      
        return () => {
          window.onresize = null;
          swiper.destroy(true, true);
        };
      }, []);
      

  return (
    <div className='testimonial'>
      <h2>
        Our Client 

      </h2>
      {/* <h5>Whether you’re selling or buying RECs, or managing your system’s maintenance, our platform has you covered. Our goal is to simplify your journey and make the process as seamless as possible.</h5> */}
      <div id="craouselContainer" className="swiper-container">
        <div className="swiper-wrapper" id="slideHolder">
          {testimonialData.map((item, index) => (
            <div key={index} className="swiper-slide">
              <div className="ContentHolder">
                <p><span>“</span>{item.review}<span>”</span></p>
              </div>
              <div className="ImgHolder">
                <img src={item.avatar} alt={item.name} />
                <h3>{item.name}</h3>
                <h6>{item.type}</h6>
              </div>
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
