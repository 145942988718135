import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import './EmailSubscription.css';

const EmailSubscription = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const email = 'info@web5solution.com'; // Hardcoded email address

        // Replace with your logic to subscribe the email
        // For example, send a request to your backend or use an API
        subscribeEmail(email);
    };

    const subscribeEmail = (email) => {
        // Example logic: Replace with your actual subscription logic
        // Here, we are just logging the email to the console
        console.log(`Subscribed web5 Solution`);
        alert(`Subscribed web5 Solution`);
    };

    return (
        <div className=''>
            <form onSubmit={handleSubmit} className='subscription-form'>
                <input type='email' name='email' placeholder='Enter your email' required />
                <button type='submit'>
                    <FaArrowRight />
                </button>
            </form>
        </div>
    );
};

export default EmailSubscription;
