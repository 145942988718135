import React from 'react';
import Lottie from 'react-lottie';
import rectangleImage from '../images/Rectangle.svg';
import './ChooseUsCard.css';

const ChooseUsCards = ({ title, description, animationData, className }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="chooseUsCard">
      <img src={rectangleImage} alt='' className="card-top" />
      <div className="card-content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div className={`card-image-container ${className}`}>
        <Lottie options={defaultOptions}  />
      </div>
    </div>
  );
};

export default ChooseUsCards;
