import './App.css';
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Navbar from './components/Navbar';
import Blog from './components/Blog';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import BlogDetails from './components/BlogDetails';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './components/NotFound';
import ServiceInternalPage2 from './components/ServicesImternalPage2';
import TwilioDataTable from './components/TwilioDataTable';
import ConsentBasedEmailPopup from './components/ConsentBasedEmailPopup';
import helloImage from "../src/images/Virtual assistant.gif";
import { FaTimes } from "react-icons/fa";
import ChatBot from './ChatBot';

const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  const toggleChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  return (
    <div className='App'>
      <button onClick={toggleChatbot} className="toggle-chatbot-btn">
        {showChatbot ? (
          <FaTimes size={24}  className='close-button2' />
        ) : (
          
          <img
            src={helloImage}
            alt="Let's Chat"
            className='chat-bot-img'
          />
        )}
      </button>

      {showChatbot && (
        <div className="chatbot-container">
         <ChatBot/>
        </div>
      )}
      <Routes>
        {/* Render routes that include Navbar and Footer */}
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <Navbar />
              <Header />
              <Services />
              <Footer />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <Navbar />
              <Header />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs"
          element={
            <>
              <Navbar />
              <Header />
              <Blog />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <Header />
              <ContactUs />
              <Footer />
            </>
          }
        />
       
        <Route
          path="/blog/:slug"
          element={
            <>
              <Navbar />
              <BlogDetails />
              <Footer />
            </>
          }
        />
        
        <Route
          path="/admin-table"
          element={
            <>
              <Navbar />
              <TwilioDataTable/>
              <Footer />
            </>
          }
        />
        <Route
          path="/:serviceTitle"
          element={
            <>
              <Navbar />
              <ServiceInternalPage2 />
              <Footer />
            </>
          }
        />
        
        <Route path="/404" element={<NotFound />} />
        {/* <Route
          path="*"
          element={<NotFound />}
        /> */}
 <Route path="*" element={<Navigate to="/" replace />} />
         <Route
          path="/consent"
          element={<ConsentBasedEmailPopup/>}
        />

        
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);

export default AppWrapper;
